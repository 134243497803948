import { HeaderContext, HeaderContextType } from "context/home/header";
import { useContext } from "react";

export const useHeaderContext = (): HeaderContextType => {
  const context = useContext(HeaderContext);
  if (!context) {
    throw new Error("useHeaderContext must be used within a HeaderProvider");
  }
  return context;
};
