import CustomIcon from "components/custom-icon";
import SettingButton from "components/settings/button";
import { useHeaderContext } from "hooks/useHeader";
import { useEffect } from "react";
import { ReactComponent as Upload } from "assets/icons/upload-white.svg";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useBlog } from "context/blog";
import Loader from "components/loader";

export default function BlogNav() {
  const { setHeaderTitle } = useHeaderContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { loadingBlogData, errorBlogData, blogData, loadingDraftData } =
    useBlog();

  function handleNewBlogUpload() {
    localStorage.removeItem("comingfromDraft");
    localStorage.removeItem("blogPrev1ew");
    navigate("/blog/add-blog");
  }

  useEffect(() => {
    setHeaderTitle("Blog post");
  }, [setHeaderTitle]);

  if (loadingBlogData || loadingDraftData) return <Loader />;

  return (
    <div className="w-full h-full overflow-y-scroll py-[24px] flex flex-col gap-6 bg-[#F8F9FA]">
      <div className="flex items-center w-full  justify-between">
        <div className=" flex flex-col h-fit">
          <h1 className="text-[#111111] text-[1rem] font-[600]">
            Overview of blog post
          </h1>

          <p className="text-[#455468] text-[14px] text-[400]">
            Manage all your blog post in one place.
          </p>
        </div>

        <button
          className="py-[8px] px-[15px] bg-[#1B4DFF] rounded-[6px] flex justify-center items-center gap-[10px] text-white"
          onClick={handleNewBlogUpload}
        >
          <CustomIcon SvgIcon={Upload} size={18} />
          <h1 className="font-[500] text-[14px] leading-[24px]">
            Upload new blog
          </h1>
        </button>
      </div>

      <div className="flex justify-between items-center h-[40px] bg-red">
        <div className="flex gap-2">
          <Link
            to="/blog"
            className={`${
              pathname === "/blog"
                ? " text-[#111111] border-b-2 border-b-[#111111] text-sm"
                : "text-[#8897AE] cursor-pointer text-sm"
            } p-1`}
          >
            Publihsed
          </Link>
          <Link
            to="/blog/draft"
            className={`${
              pathname === "/blog/draft"
                ? " text-[#111111] border-b-2 border-b-[#111111] text-sm"
                : "text-[#8897AE] cursor-pointer text-sm"
            } p-1 `}
          >
            In Draft
          </Link>
        </div>
      </div>
      <div className="h-[calc(100%-40px)] w-full">
        <Outlet />
      </div>
    </div>
  );
}
