import { useEffect, useState } from "react";
import { Modal } from "antd";
import { ReactComponent as emailIcon } from "assets/icons/email.svg";
import CustomIcon from "components/custom-icon";
import useFetch from "hooks/useFetch";
import { getUserByIdService, uploadFilesService } from "services/app";
import { updatePictureProfile, updateProfile } from "services/teams";
import { toast } from "sonner";
import { useAppContext } from "context/home/app";
import { getRolesService } from "services/business";

interface Props {
  open: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  loading: boolean;
}

const ProfileModal: React.FC<Props> = ({
  open,
  handleOk,
  handleCancel,
  loading,
}) => {
  const { setUserInfo, getUserInfo } = useAppContext();
  const userInfo = getUserInfo();
  const [profileImage, setProfileImage] = useState<string | null>(
    userInfo?.profilePicture ||
      "https://i.pinimg.com/474x/16/3e/39/163e39beaa36d1f9a061b0f0c5669750.jpg"
  );
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [firstName, setFirstName] = useState<any>(userInfo?.firstName || "");
  const [lastName, setLastName] = useState<any>(userInfo?.lastName || "");
  const [email, setEmail] = useState<any>(userInfo?.email || "");
  const [position, setPosition] = useState<any>(userInfo?.position || "");
  const [roles, setRoles] = useState<any>([]);

  const {
    data: uploadData,
    error: uploadError,
    loading: uploadLoading,
    fetchData: fetchDataUpload,
  } = useFetch<any>();

  const {
    data: updateProfileData,
    error: updateProfileError,
    loading: updateProfileLoading,
    fetchData: fetchDataUpdateProfile,
  } = useFetch<any>();
  const {
    data: updateProfilePicData,
    error: updateProfilePicError,
    loading: updateProfilePicLoading,
    fetchData: fetchDataUpdateProfilePic,
  } = useFetch<any>();
  const {
    data: getUserInfoByIdData,
    error: getUserInfoByIdError,
    loading: getUserInfoByIdLoading,
    fetchData: fetchDataGetUserInfoById,
  } = useFetch<any>();
  const {
    data: getRolesData,
    error: getRolesError,
    loading: getRolesLoading,
    fetchData: fetchDataGetRoles,
  } = useFetch<any>();

  const handleUpload = (file: File | null) => {
    if (file) {
      fetchDataUpload(() => uploadFilesService(file));
    }
  };

  useEffect(() => {
    fetchDataGetRoles(() => getRolesService());
  }, []);

  useEffect(() => {
    setRoles(getRolesData?.data?.content);
  }, [getRolesData]);

  useEffect(() => {
    if (uploadData?.data.secure_url) {
      const body = { pictureUrl: uploadData.data.secure_url };
      fetchDataUpdateProfilePic(() => updatePictureProfile(body));
    }
  }, [uploadData]);

  const matchingRole = roles?.find(
    (role: any) => role.name === userInfo?.roleName
  );

  const filteredNotificationSettings = {
    pushBusinessAndSubscriptionToApp:
      userInfo.notificationSettings.pushBusinessAndSubscriptionToApp,
    pushBusinessAndSubscriptionToMail:
      userInfo.notificationSettings.pushBusinessAndSubscriptionToMail,
    newBusinessOnboarded: userInfo.notificationSettings.newBusinessOnboarded,
    frequencyOfNotification:
      userInfo.notificationSettings.frequencyOfNotification,
    assignedTeamMemberToApp:
      userInfo.notificationSettings.assignedTeamMemberToApp,
    assignedTeamMemberToMail:
      userInfo.notificationSettings.assignedTeamMemberToMail,
    assignedResponsibility:
      userInfo.notificationSettings.assignedResponsibility,
    subscriptionExpiration:
      userInfo.notificationSettings.subscriptionExpiration,
    changesMadeToControl: userInfo.notificationSettings.changesMadeToControl,
    reminderToApp: userInfo.notificationSettings.reminderToApp,
    reminderToMail: userInfo.notificationSettings.reminderToMail,
    moreActivityToApp: userInfo.notificationSettings.moreActivityToApp,
    moreActivityToMail: userInfo.notificationSettings.moreActivityToMail,
  };

  const profileBody = {
    username: userInfo.username,
    firstName,
    lastName,
    email,
    department: position,
    position,
    partner: userInfo?.partner,
    rolePublicId: matchingRole?.publicId,
    automaticLogoutTime: userInfo.automaticLogoutTime,
    sessionTimeout: userInfo.sessionTimeout,
    notificationSettings: filteredNotificationSettings,
  };

  const handleUpdateProfile = () => {
    fetchDataUpdateProfile(() =>
      updateProfile(userInfo?.publicId, profileBody)
    );
  };

  const getUpdatedUserProfile = () => {
    fetchDataGetUserInfoById(() => getUserByIdService(userInfo?.publicId));
  };

  useEffect(() => {
    if (updateProfilePicData) {
      toast.success("Profile picture updated successfully");
      getUpdatedUserProfile();
      handleCancel();
    }
  }, [updateProfilePicData]);

  useEffect(() => {
    if (getUserInfoByIdData) {
      setUserInfo(getUserInfoByIdData?.data);
    }
  }, [getUserInfoByIdData]);

  useEffect(() => {
    if (updateProfileData) {
      console.log("updated data", updateProfileData?.data);
      setUserInfo(updateProfileData?.data);
      toast.success("Profile updated successfully");
      handleCancel();
    }
  }, [updateProfileData]);

  useEffect(() => {
    if (uploadError) toast.error(uploadError);
    if (updateProfileError) toast.error(updateProfileError);
  }, [uploadError, updateProfileError]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setProfileImage(URL.createObjectURL(file));
      handleUpload(file);
    }
  };

  const isLoading = loading || uploadLoading || updateProfileLoading;

  return (
    <Modal
      open={open}
      title="Edit Profile"
      centered
      onOk={handleUpdateProfile}
      onCancel={handleCancel}
      width={450}
      footer={
        <div className="w-full flex items-center justify-end gap-6 mt-[2rem] mb-4">
          <button
            className="py-2 px-4 rounded-md bg-white border font-medium text-sm border-[#D7DFE9]"
            key="back"
            onClick={handleCancel}
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            className="py-2 px-4 rounded-md text-white font-medium text-sm bg-[#1B4DFF] border border-[#1B4DFF]"
            key="submit"
            onClick={handleUpdateProfile}
            disabled={isLoading}
          >
            {isLoading ? "Updating Profile..." : "Update Profile"}
          </button>
        </div>
      }
    >
      <p>Send us a message with details of your inquiry.</p>

      <div className="mt-5">
        <form className="flex flex-col gap-4">
          {/* Avatar Section */}
          <div className="w-full flex flex-col items-center justify-center">
            <div className="w-[80px] aspect-square rounded-full overflow-hidden">
              <img
                src={profileImage || undefined}
                className="w-full h-full object-cover"
                alt="Profile"
              />
            </div>

            <p className="text-sm font-medium text-[#111111] mt-3">
              Upload avatar
            </p>
            <p className="text-sm text-[#455468] my-3">
              Min 400x400px, PNG or JPEG
            </p>

            <div className="w-full flex items-center justify-center gap-4">
              <button
                type="button"
                className="py-2 px-4 rounded-md text-[#CB1A14] font-medium text-sm bg-[#FFF5F4] border border-[#FFF5F4]"
                onClick={() => {
                  setProfileImage(
                    "https://i.pinimg.com/474x/16/3e/39/163e39beaa36d1f9a061b0f0c5669750.jpg"
                  );
                  setSelectedFile(null);
                }}
              >
                Remove
              </button>
              <label className="py-2 px-4 rounded-md bg-white border font-medium text-sm border-[#D7DFE9] cursor-pointer">
                Change Photo
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </label>
            </div>
          </div>

          {/* Full Name */}
          <div className="flex items-center gap-4">
            <div>
              <label htmlFor="" className="font-medium text-[#455468] text-sm">
                First name<sup className="text-[#1B4DFF]">*</sup>
              </label>
              <input
                className="w-full border border-[#AFBACA] p-4 rounded-md placeholder:text-[#8897AE] placeholder:text-sm mt-1"
                type="text"
                placeholder="Olamide"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)} // Update first name
              />
            </div>

            <div>
              <label htmlFor="" className="font-medium text-[#455468] text-sm">
                Last name<sup className="text-[#1B4DFF]">*</sup>
              </label>
              <input
                className="w-full border border-[#AFBACA] p-4 rounded-md placeholder:text-[#8897AE] placeholder:text-sm mt-1"
                type="text"
                placeholder="Abiodun"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)} // Update last name
              />
            </div>
          </div>

          {/* Email */}
          <div>
            <label htmlFor="" className="font-medium text-[#455468] text-sm">
              Email<sup className="text-[#1B4DFF]">*</sup>
            </label>
            <div className="flex items-center gap-2 border border-[#AFBACA] p-4 rounded-md mt-1">
              <CustomIcon SvgIcon={emailIcon} size={20} />
              <input
                className="w-full placeholder:text-[#8897AE] placeholder:text-sm border-l px-2 bg-transparent outline-none"
                type="email"
                placeholder="olamide@gmail.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)} // Update email
              />
            </div>
          </div>

          {/* Position */}
          <div>
            <label htmlFor="" className="font-medium text-[#455468] text-sm">
              Position<sup className="text-[#1B4DFF]">*</sup>
            </label>
            <input
              className="w-full border border-[#AFBACA] p-4 rounded-md placeholder:text-[#8897AE] placeholder:text-sm mt-1"
              type="text"
              placeholder="Software Engineer"
              value={position}
              onChange={(e) => setPosition(e.target.value)} // Update position
            />
          </div>
          {/* <div>
            <label htmlFor="" className="font-medium text-[#455468] text-sm">
              Department<sup className="text-[#1B4DFF]">*</sup>
            </label>
            <input
              className="w-full border border-[#AFBACA] p-4 rounded-md placeholder:text-[#8897AE] placeholder:text-sm mt-1"
              type="text"
              placeholder="Software Engineer"
              value={department}
              onChange={(e) => setDepartment(e.target.value)} // Update position
            />
          </div> */}
        </form>
      </div>
    </Modal>
  );
};

export default ProfileModal;
