// helpers/filters.ts

import React, { ChangeEvent, useEffect, useRef, useState } from "react";

export const useLastUpdated = () => {
  const [LastUpdated, setLastUpdated] = useState<string[]>([]);

  const handleAddLastUpdated = (label: string) => {
    setLastUpdated((prevFilter) => {
      if (!prevFilter.includes(label)) {
        return [...prevFilter, label];
      }
      return prevFilter;
    });
  };

  const handleRemoveLastUpdated = (label: string) => {
    setLastUpdated((prevFilter) => prevFilter.filter((item) => item !== label));
  };

  const clearLastUpdated = () => {
    setLastUpdated([]);
  };

  return {
    LastUpdated,
    handleAddLastUpdated,
    handleRemoveLastUpdated,
    clearLastUpdated,
  };
};

// helpers/input.ts
interface InputProps {
  icon: React.ReactNode;
  placeholder: string;
  type: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const DraftBlogInput = ({
  icon,
  placeholder,
  type,
  value,
  onChange,
}: InputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isInputFocused, setIsInputFocused] = useState(false);

  useEffect(() => {
    if (isInputFocused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isInputFocused]);

  return (
    <div className="flex rounded-md items-center bg-white shadow-md p-[10px]">
      <span className="border-r px-[10px]">{icon}</span>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        ref={inputRef}
        autoFocus
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
        onChange={onChange}
        className="w-[560px] ml-[10px] focus:outline-none"
      />
    </div>
  );
};
