import SelectComponent from "helpers/select-input";
import olamide from "../../../assets/images/olamide.jpg";
import mike from "../../../assets/images/mike.jpg";
import stella from "../../../assets/images/stella.jpg";
import kamsi from "../../../assets/images/kamsi.jpg";
import dayo from "../../../assets/images/dayo.jpg";
import anosike from "../../../assets/images/anosike.jpg";
import CustomIcon from "components/custom-icon";
import { ReactComponent as mail } from "assets/icons/email1.svg";
import { ReactComponent as upload } from "assets/icons/upload-01.svg";
import { useEffect, useState } from "react";

const year = [
  { value: "15 days", label: "15 days" },
  { value: "30 days", label: "30 days" },
  { value: "60 days", label: "60 days" },
  { value: "1 year", label: "1 year" },
  { value: "2 years", label: "2 years" },
];

const database = [
  { value: "POSTGRESS", label: "POSTGRESS" },
  { value: "MYSQL", label: "MYSQL" },
  { value: "SQL", label: "SQL" },
];

const plan = [
  { value: "free", label: "Free plan" },
  { value: "paid", label: "Paid plan" },
];

const framework = [
  { value: "ISO/IEC 27001:2022", label: "ISO/IEC 27001:2022" },
  { value: "SOC 2", label: "SOC 2" },
  { value: "ISO 20000", label: "ISO 20000" },
  { value: "PCI DSS", label: "PCI DSS" },
  { value: "NDPR", label: "NDPR" },
  { value: "ISO 22301", label: "ISO 22301" },
];

const team = [
  { value: "olamide", label: "Olamide Abiodun", image: olamide },
  { value: "stella", label: "Ralph Stella", image: stella },
  { value: "mike", label: "Mike Stephen", image: mike },
  { value: "kamsi", label: "Kamsi Somto", image: kamsi },
  { value: "dayo", label: "Dayo Ahmed", image: dayo },
  { value: "anosike", label: "Anosike Okomanyi", image: anosike },
];

interface Business {
  appAccess: string;
  appModules: [];
  approved: boolean;
  businessStatus: string;
  companyDomain: string;
  companyEmail: string;
  companyName: string;
  createdBy: string;
  createdDate: string;
  customerSuccessManager: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  publicId: string;
  salesRep: string;
  subscriptionType: string;
}

interface Props {
  business: Business;
}

export default function ApprovalBusinessInfo({ business }: any) {
  const [selectedRadio, setSelectedRadio] = useState<string | null>(null);
  const [name, setName] = useState<string[]>([]);

  const handleRadioChange = (value: string) => {
    setSelectedRadio(value);
  };

  useEffect(() => {
    if (business?.frameworks) {
      const frameworksNames = business.frameworks.map(
        (item: { name: string }) => ({
          value: item.name,
          label: item.name,
        })
      );
      setName(frameworksNames);
    }
  }, [business?.frameworks]);

  console.log(name);
  return (
    <div className="w-full bg-white text-[#455468] border border-[#D7DFE9] px-6 rounded-md">
      <form>
        <div className="flex gap-20 border-b border-[#D7DFE9] py-6">
          <div className="w-[350px]">
            <p className="text-[#111111] text-sm font-medium">Company info</p>
            <p className="text-sm">Company name and email</p>
          </div>

          <div className="w-full">
            <div>
              <label htmlFor="" className="font-medium text-sm">
                Company name<sup className="text-[#1B4DFF]">*</sup>
              </label>
              <input
                className="w-full bg-white border border-[#AFBACA] rounded-md p-2.5 placeholder:text-[#8897AE] placeholder:text-sm mt-1"
                type="text"
                placeholder="Enter company name"
                value={business?.companyName}
              />
            </div>

            <div className="pt-4">
              <label htmlFor="" className="font-medium text-sm">
                Email address<sup className="text-[#1B4DFF]">*</sup>
              </label>
              <div className="flex items-center gap-2 p-2.5 border border-[#AFBACA] rounded-md mt-1">
                <CustomIcon SvgIcon={mail} size={20} />
                <input
                  className="w-full bg-white border-l border-[#AFBACA] placeholder:text-[#8897AE] placeholder:text-sm px-2"
                  type="text"
                  placeholder="example@email.com"
                  value={business?.companyEmail}
                />
              </div>
            </div>
            <p className="pt-4">Must be tied to a company domain</p>
          </div>
        </div>
        {/* super admin */}
        <div className="flex gap-20 border-b border-[#D7DFE9] py-6">
          <div className="w-[350px]">
            <p className="text-[#111111] text-sm font-medium">
              Company Super Admin
            </p>
            <p className="text-sm">Super admin information</p>
          </div>

          <div className="w-full">
            <div className="flex gap-7">
              <div className="w-full">
                <label htmlFor="" className="font-medium text-sm">
                  First name<sup className="text-[#1B4DFF]">*</sup>
                </label>
                <input
                  className="w-full bg-white border border-[#AFBACA] rounded-md p-2.5 placeholder:text-[#8897AE] placeholder:text-sm mt-1"
                  type="text"
                  placeholder="First name"
                  value={business?.tenantAdmins[0]?.firstName}
                />
              </div>

              <div className="w-full">
                <label htmlFor="" className="font-medium text-sm">
                  Last name<sup className="text-[#1B4DFF]">*</sup>
                </label>
                <input
                  className="w-full bg-white border border-[#AFBACA] rounded-md p-2.5 placeholder:text-[#8897AE] placeholder:text-sm mt-1"
                  type="text"
                  placeholder="Last name"
                  value={business?.tenantAdmins[0]?.lastName}
                />
              </div>
            </div>

            <div className="pt-4">
              <label htmlFor="" className="font-medium text-sm">
                Email address<sup className="text-[#1B4DFF]">*</sup>
              </label>
              <div className="flex items-center gap-2 p-2.5 border border-[#AFBACA] rounded-md mt-1">
                <CustomIcon SvgIcon={mail} size={20} />
                <input
                  className="w-full bg-white border-l border-[#AFBACA] placeholder:text-[#8897AE] placeholder:text-sm px-2"
                  type="text"
                  placeholder="example@email.com"
                  value={business?.tenantAdmins[0]?.email}
                />
              </div>
            </div>
            <p className="pt-4">Must be tied to a company domain</p>
          </div>
        </div>

        {/* Database Option */}
        {/* <div className='flex gap-20 border-b border-[#D7DFE9] py-6'>
					<div className='w-[350px]'>
						<p className='text-[#111111] text-sm font-medium'>
							Database Option
						</p>
						<p className='text-sm'>Client's preferred db Option</p>
					</div>

					<div className='w-full'>
						<p className='text-sm text-[#111111] font-medium'>
							Select client’s db Option
						</p>
						<div className='flex gap-7 mt-1 border-b border-[#D7DFE9] pb-6'>
							<div
								className={`flex items-center p-2.5 border rounded-md w-[350px] ${
									selectedRadio === 'schema'
										? 'border-blue-600 bg-lightblue'
										: 'border-[#AFBACA]'
								}`}>
								<input
									id='bordered-radio-1'
									type='radio'
									value='schema'
									name='bordered-radio'
									className='w-4.5 h-4.5 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500'
									checked={selectedRadio === 'schema'}
									onChange={() => handleRadioChange('schema')}
								/>
								<label
									htmlFor='bordered-radio-1'
									className='w-full ms-2 font-medium'>
									Schema
								</label>
							</div>

							<div
								className={`flex items-center p-2.5 border rounded-md w-[350px] ${
									selectedRadio === 'database'
										? 'border-blue-600 bg-lightblue'
										: 'border-[#AFBACA]'
								}`}>
								<input
									id='bordered-radio-1'
									type='radio'
									value='database'
									name='bordered-radio'
									className='w-4.5 h-4.5 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500'
									checked={selectedRadio === 'database'}
									onChange={() => handleRadioChange('database')}
								/>
								<label
									htmlFor='bordered-radio-1'
									className='w-full ms-2 font-medium'>
									Database
								</label>
							</div>
						</div>

						<div className='flex gap-7 mt-4'>
							<div className='w-full'>
								<label htmlFor='' className='font-medium text-sm'>
									Username<sup className='text-[#1B4DFF]'>*</sup>
								</label>
								<input
									className='w-full bg-white border border-[#AFBACA] rounded-md p-2.5 placeholder:text-[#8897AE] placeholder:text-sm mt-1'
									type='text'
									placeholder='Username'
								/>
							</div>

							<div className='w-full'>
								<label htmlFor='' className='font-medium text-sm'>
									db type<sup className='text-[#1B4DFF]'>*</sup>
								</label>
								<div className='mt-1'>
									<SelectComponent
										placeholder='eg. Yearly, Quarterly'
										options={database}
									/>
								</div>
							</div>
						</div>

						<div className='flex gap-7 mt-4'>
							<div className='w-full'>
								<label htmlFor='' className='font-medium text-sm'>
									db URL<sup className='text-[#1B4DFF]'>*</sup>
								</label>
								<div className='flex items-center gap-2 p-2.5 border border-[#AFBACA] rounded-md mt-1'>
									<p className='text-sm font-medium'>http://</p>
									<input
										className='w-full bg-white border-l border-[#AFBACA] placeholder:text-[#8897AE] placeholder:text-sm px-2'
										type='text'
										placeholder='example.com'
										value={business?.email || ''}
									/>
								</div>
							</div>

							<div className='w-full'>
								<label htmlFor='' className='font-medium text-sm'>
									db type<sup className='text-[#1B4DFF]'>*</sup>
								</label>
								<div className='mt-1'>
									<SelectComponent
										placeholder='eg. Yearly, Quarterly'
										options={database}
									/>
								</div>
							</div>
						</div>
					</div>
				</div> */}

        {/* Documentations */}
        <div className="flex gap-20 border-b border-[#D7DFE9] py-6">
          <div className="w-[350px]">
            <p className="text-[#111111] text-sm font-medium">Documentations</p>
            <p className="text-sm"> Documentations and agreements</p>
          </div>

          <div className="w-full">
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center gap-4">
                <div className="">
                  <div className="w-10 aspect-square rounded-full flex items-center justify-center bg-[#F2F5FF]">
                    <CustomIcon SvgIcon={upload} size={20} />
                  </div>
                </div>

                <div>
                  <p className="text-[#111111] text-sm font-medium">
                    Non Disclosure Agreement (NDA){" "}
                    <sup className="text-[#1B4DFF]">*</sup>
                  </p>
                  <div className="flex items-center gap-4">
                    <p className="text-sm"> PDF format</p>
                    <div className="w-2 aspect-square rounded-full bg-black"></div>
                    <p className="text-sm"> Max.5MB</p>
                  </div>
                </div>
              </div>

              <div>
                <label
                  htmlFor="uploadFile1"
                  className="flex bg-[#F2F5FF] text-[#1B4DFF] text-sm py-[8px] px-[15px] outline-none rounded-md cursor-pointer items-center justify-center font-medium"
                >
                  Upload
                  <input type="file" id="uploadFile1" className="hidden" />
                </label>
              </div>
            </div>
          </div>
        </div>

        {/* Subscription */}
        <div className="flex gap-20 border-b border-[#D7DFE9] py-6">
          <div className="w-[350px]">
            <p className="text-[#111111] text-sm font-medium">Subscription</p>
            <p className="text-sm">
              Manage subscription type, framework and duration
            </p>
          </div>

          <div className="w-full">
            <div className="w-full">
              <label htmlFor="" className="font-medium text-sm">
                Subscription type<sup className="text-[#1B4DFF]">*</sup>
              </label>
              <div className="mt-1">
                {/* <SelectComponent
									placeholder='eg. Free plan, Paid plan'
									options={plan}
								/> */}
                <input
                  className="w-full bg-white border border-[#AFBACA] rounded-md p-2.5 placeholder:text-[#8897AE] placeholder:text-sm mt-1 capitalize"
                  type="text"
                  placeholder="eg, Free plan"
                  value={business?.subscriptionType}
                />
              </div>
            </div>
            <div className="w-full mt-4">
              <label htmlFor="" className="font-medium text-sm">
                Framework(s) subscibed to
                <sup className="text-[#1B4DFF]">*</sup>
              </label>
              <div className="mt-1">
                <SelectComponent
                  placeholder="eg. ISO 27001, PCI DSS"
                  // options={framework}
                  value={name}
                  isMulti={true}
                />
              </div>
            </div>
            <div className="w-full mt-4">
              <label htmlFor="" className="font-medium text-sm">
                Subscription duration<sup className="text-[#1B4DFF]">*</sup>
              </label>
              <div className="mt-1">
                <SelectComponent
                  placeholder="eg. 30 days, 1 year, 2 years"
                  options={year}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Business */}
        <div className="flex gap-20 border-b border-[#D7DFE9] py-6">
          <div className="w-[350px]">
            <p className="text-[#111111] text-sm font-medium">
              Business Support Representatives
            </p>
            <p className="text-sm">
              Manage Vegeel's personnel assigned to this business
            </p>
          </div>

          <div className="w-full">
            <div className="w-full">
              <label htmlFor="" className="font-medium text-sm">
                Sales representative<sup className="text-[#1B4DFF]">*</sup>
              </label>
              <div className="mt-1">
                <input
                  className="w-full bg-white border border-[#AFBACA] rounded-md p-2.5 placeholder:text-[#8897AE] placeholder:text-sm mt-1 capitalize"
                  type="text"
                  placeholder="eg, Free plan"
                  value={business?.salesRep}
                />
              </div>
            </div>
            <div className="w-full mt-4">
              <label htmlFor="" className="font-medium text-sm">
                Customer success manager
                <sup className="text-[#1B4DFF]">*</sup>
              </label>
              <div className="mt-1">
                <input
                  className="w-full bg-white border border-[#AFBACA] rounded-md p-2.5 placeholder:text-[#8897AE] placeholder:text-sm mt-1 capitalize"
                  type="text"
                  placeholder="eg, Free plan"
                  value={business?.customerSuccessManager}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
