import Roles from "components/settings/roles";
import { useSettings } from "context/settings";
import React from "react";
import AddNewPermission from "./add-permissions";

export default function Permissions() {
  const { rolesData } = useSettings();
  return (
    <div className="flex flex-col gap-[1rem]">
      <div className="w-full flex flex-col h-fit">
        <h1 className="text-[#111111] text-[1rem] font-[500]">Access Roles</h1>

        <p className="text-[#455468] text-[14px] text-[400]">
          Manage permissions and access levels of different access groups in
          your organization.
        </p>
      </div>
      {/* <AddNewPermission /> */}

      {rolesData.map((data: any) => (
        <Roles key={data.id} data={data} />
      ))}
    </div>
  );
}
