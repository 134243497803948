import apiService from "./api";

const baseurl = process.env.REACT_APP_BASE_URL;

const getAllTeam = async () => {
  const url = `/personnel?size=${1000}`;
  const method = "get";

  return apiService(url, method, null, true);
};

const getRoles = async () => {
  const url = `/roles`;
  const method = "get";

  return apiService(url, method, null, true);
};

const addNewTeam = async (body: any) => {
  const url = "/personnel";
  const method = "post";
  return apiService(url, method, body, true);
};
const replyHelp = async (body: any) => {
  const url = "/personnel/help-reply";
  const method = "post";
  return apiService(url, method, body, true);
};

const updatePictureProfile = async (body: any) => {
  const url = "/personnel/update-user-profile-pics";
  const method = "post";
  return apiService(url, method, body, true);
};

const updateProfile = async (publicId: any, body: any) => {
  const url = `/personnel/${publicId}`;
  const method = "put";
  return apiService(url, method, body, true);
};

const getTeamById = async (publicId: any) => {
  const url = `/personnel/${publicId}`;
  const method = "get";

  return apiService(url, method, null, true);
};

const getBusinessById = async (publicId: any) => {
  const url = `/vegeel/business/${publicId}`;
  const method = "get";

  return apiService(url, method, null, true);
};

const approveBusiness = async (publicId: any) => {
  const url = `/vegeel/business/approve-new-business/${publicId}`;
  const method = "get";

  return apiService(url, method, null, true);
};

const resendApproveLink = async (publicId: any) => {
  const url = `/vegeel/business/resend-admin-invite/${publicId}`;
  const method = "get";

  return apiService(url, method, null, true);
};

const editTeam = async (publicId: any, body: any) => {
  const url = `/personnel/${publicId}`;
  const method = "put";
  // const requestData = body;
  return apiService(url, method, body, true);
};
const deleteTeam = async (publicId: any) => {
  const url = `/personnel/${publicId}`;
  const method = "delete";
  // const requestData = body;
  return apiService(url, method, null, true);
};

export {
  getAllTeam,
  getTeamById,
  editTeam,
  getRoles,
  addNewTeam,
  deleteTeam,
  getBusinessById,
  approveBusiness,
  resendApproveLink,
  updatePictureProfile,
  updateProfile,
  replyHelp,
};
