// CustomIcon.tsx
import React from "react";

const CustomIcon: React.FC<any> = ({ SvgIcon, size = 12 }) => {
  return (
    <div style={{ width: size, height: size }}>
      <SvgIcon width="100%" height="100%" />
    </div>
  );
};

export default CustomIcon;
