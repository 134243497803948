import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import useFetch from "hooks/useFetch";
import { getBlogs, getDraftBlogs } from "services/blog";

interface IDataResponse {
  isSuccess: boolean;
  isError: boolean;
  description: string;
  status: string;
  data: IDataContent;
  timestamp: string;
}
interface IContent {
  createdDate: string;
  publicId: string;
  createdBy: string;
  title: string;
  content: string;
  author: string;
  category: string[];
  subTopics: string[];
  image: string;
  visibility: string;
  published: boolean;
}

interface IDataContent {
  content: IContent[];
}

interface BlogContextProps {
  blogData: IDataContent;
  loadingBlogData: boolean;
  errorBlogData: any;
  blogDraft: [];
  loadingDraftData: boolean;
  errorDraftData: any;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
  updatedAt: string;
  preview: any;
  setPreview: any;
  setComingFromDraft: React.Dispatch<React.SetStateAction<boolean>>;
  comingFromDraft: boolean;
}

interface BlogProviderProps {
  children: ReactNode;
}

const BlogContext = createContext<BlogContextProps | undefined>(undefined);

export const BlogProvider: React.FC<BlogProviderProps> = ({ children }) => {
  const {
    data: blogDataResponse,
    error: blogError,
    loading: blogLoading,
    fetchData: fetchBlogs,
  } = useFetch<IDataResponse>();

  const {
    data: draftDataResponse,
    error: draftError,
    loading: draftLoading,
    fetchData: fetchDrafts,
  } = useFetch<IDataResponse>();

  const [blogData, setBlogData] = useState<IDataContent>({ content: [] });
  const [blogDraft, setBlogDraft] = useState<any>([]);
  const [isReloading, setReload] = useState<boolean>(false);
  const [updatedAt, setUpdatedAt] = useState<string>("");
  const [preview, setPreview] = useState<boolean>(false);
  const [comingFromDraft, setComingFromDraft] = useState<boolean>(false);

  useEffect(() => {
    fetchBlogs(() => getBlogs());
    fetchDrafts(() => getDraftBlogs());
    if (isReloading) {
      setReload(false);
    }
  }, [isReloading]);

  useEffect(() => {
    if (blogDataResponse?.isSuccess && blogDataResponse.data) {
      const publishedBlogs = blogDataResponse.data.content.filter(
        (blog) => blog.published === true
      );
      setBlogData({ content: publishedBlogs });
      setUpdatedAt(blogDataResponse.timestamp);
    }
  }, [blogDataResponse]);

  useEffect(() => {
    if (draftDataResponse?.isSuccess && draftDataResponse.data) {
      setBlogDraft(draftDataResponse.data);
    }
  }, [draftDataResponse]);

  return (
    <BlogContext.Provider
      value={{
        comingFromDraft,
        setComingFromDraft,
        preview,
        setPreview,
        updatedAt,
        blogData,
        loadingBlogData: blogLoading,
        errorBlogData: blogError,
        blogDraft,
        loadingDraftData: draftLoading,
        errorDraftData: draftError,
        setReload,
      }}
    >
      {children}
    </BlogContext.Provider>
  );
};

export const useBlog = (): BlogContextProps => {
  const context = useContext(BlogContext);
  if (!context) {
    throw new Error("useBlog must be used within a BlogProvider");
  }
  return context;
};
