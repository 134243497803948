import { SidebarTopProps } from "types";
import { ReactComponent as IconHome } from "assets/icons/IconHome.svg";
import { ReactComponent as IconFramework } from "assets/icons/cube.svg";
import { ReactComponent as IconBriefcase } from "assets/icons/briefcase.svg";
import { ReactComponent as IconCoinHand } from "assets/icons/coin-hand.svg";
import { ReactComponent as IconBarChartSquare } from "assets/icons/bar-chart-square-02.svg";
import { ReactComponent as IconUserProfiles } from "assets/icons/users-profiles-02.svg";
import { ReactComponent as IconApprovals } from "assets/icons/check-broken-01.svg";
import { ReactComponent as IconHelpRequests } from "assets/icons/help-circle-contained-01.svg";
import { ReactComponent as IconGovernorSettings } from "assets/icons/IconGovernorSettings.svg";
import { ReactComponent as IconBlogPost } from "assets/icons/book-02.svg";
import { ReactComponent as IconCareerPost } from "assets/icons/luggage-04.svg";

export const sidebarTop: SidebarTopProps[] = [
  {
    title: "Home",
    icon: IconHome,
    link: "/",
  },
  {
    title: "Business",
    icon: IconBriefcase,
    link: "/businesses",
  },
  {
    title: "Subscription",
    link: "/subscription",
    icon: IconCoinHand,
  },
  {
    title: "Analytics",
    link: "/analytics",
    icon: IconBarChartSquare,
  },
  {
    title: "Team",
    link: "/team",
    icon: IconUserProfiles,
  },
];
export const sidebarMiddle: SidebarTopProps[] = [
  {
    title: "Frameworks",
    icon: IconFramework,
    link: "/frameworks",
  },
  {
    title: "Approvals",
    icon: IconApprovals,
    link: "/approvals",
  },
  {
    title: "Help Requests",
    link: "/help-requests",
    icon: IconHelpRequests,
  },
  {
    title: "Settings",
    link: "/settings",
    icon: IconGovernorSettings,
  },
];
export const sidebarBottom: SidebarTopProps[] = [
  {
    title: "Blog Post",
    icon: IconBlogPost,
    link: "/blog",
  },
  {
    title: "Career Post",
    icon: IconCareerPost,
    link: "/career-post",
  },
];

export const subscription_type = [
  {
    percentage: 35,
    label: "subscribers",
    badgeText: "Paid plan",
    badgeColor: "bg-[#ECFDF3]",
    dotColor: "bg-[#027A48]",
    badgeTextColor: "text-[#EA6F03]",
  },
  {
    percentage: 65,
    label: "subscribers",
    badgeText: "Free plan",
    badgeColor: "bg-[#F2F5FF]",
    dotColor: "bg-[#FEF0C7]",
    badgeTextColor: "text-[#4A72FF]",
  },
];
