import React from "react";

interface CloseIconProps {
  color?: string;
  size?: number;
}

const CloseIcon: React.FC<CloseIconProps> = ({
  color = "#455468",
  size = 18,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6L6 12M12 12L6 6"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CloseIcon;
