import { useState } from "react";

function useModal() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModal = () => setOpen(true);
  const handleOk = (onOkCallback?: () => void) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
      if (onOkCallback) onOkCallback();
    }, 3000);
  };
  const handleCancel = () => setOpen(false);

  return { open, loading, showModal, handleOk, handleCancel };
}

export default useModal;
