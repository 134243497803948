import apiService from "./api";
const baseUrl = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

// const uploadFilesService = async (file: any) => {
//   const formData = new FormData();
//   formData.append("file", file);

//   return apiService(
//     "/utils/files/upload",
//     "post",
//     formData,
//     true,
//     {},
//     {},
//     baseUrl
//   );
// };

const uploadFilesService = async (file: any) => {
  const fileName = file.name.replace(/[\s-]+/g, "_");
  console.log(fileName);
  const updatedFile = new File([file], fileName, { type: file.type });
  const formData = new FormData();
  formData.append("file", updatedFile);

  return apiService(
    "/utils/files/upload",
    "post",
    formData,
    true,
    {},
    {},
    baseUrl
  );
};

const getUserByIdService = async (id: any) => {
  return apiService(
    `/personnel/${id}`,
    "get",
    null,
    true,
    {},
    undefined,
    baseUrl
  );
};

const refreshFrameworkService = async () => {
  // const headers = {
  //   "X-API-KEY": apiKey,
  //   // "Content-Type": "multipart/form-data",
  // };

  return apiService(
    "/frameworks/refresh",
    "get",
    null,
    true,
    {},
    undefined,
    baseUrl
  );
};

export { uploadFilesService, getUserByIdService, refreshFrameworkService };
