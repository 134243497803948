import React, { InputHTMLAttributes } from "react";
interface ButtonProps extends InputHTMLAttributes<HTMLButtonElement> {
  text: string;
  icon?: any;
  variant: "contained" | "outline" | "plain" | "danger";
  onClick?: () => void;
}

export default function OnboardingButton({
  icon,
  text,
  variant,
  onClick,
}: ButtonProps) {
  const getButtonVariant = () => {
    switch (variant) {
      case "contained":
        return "bg-[#1B4DFF] text-white";
      case "danger":
        return "bg-[#E92215] text-white";
      case "outline":
        return "border border-[#D7DFE9] text-[#455468]";
      case "plain":
        return "";

      default:
        return "";
    }
  };

  return (
    <button
      onClick={onClick}
      className={`w-full h-[40px] flex justify-center items-center gap-3 rounded-md text-sm leading-[24px] capitalize font-medium ${getButtonVariant()}`}
    >
      <h1>{text}</h1>
      <span>{icon}</span>
    </button>
  );
}
