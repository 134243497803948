import apiService from "./api";

const baseurl = process.env.REACT_APP_BASE_URL;

const getRoles = async () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService(
    "/roles",
    "get",
    undefined,
    true,
    headers,
    undefined,
    baseurl
  );
};
const getSettingsNotification = async (id: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService(
    `/settings/notification?staffId=${id}`,
    "get",
    undefined,
    true,
    headers,
    undefined,
    baseurl
  );
};
const getPersonel = async (id: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService(
    `/personnel/${id}`,
    "get",
    undefined,
    true,
    headers,
    undefined,
    baseurl
  );
};
const updatePersonel = async (id: any, body: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService(
    `/personnel/${id}`,
    "put",
    body,
    true,
    headers,
    undefined,
    baseurl
  );
};
const updateSettingsNotification = async (id: any, body: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService(
    `settings/notification?staffId=${id}`,
    "put",
    body,
    true,
    headers,
    undefined,
    baseurl
  );
};

const enableMultifactorauthentication = async () => {
  return apiService(
    "/personnel/setup-2fa",
    "post",
    undefined,
    true,
    {},
    undefined,
    baseurl
  );
};

const disableMultifactorauthentication = async () => {
  return apiService(
    "/personnel/disable-2fa",
    "post",
    undefined,
    true,
    {},
    undefined,
    baseurl
  );
};
export {
  updatePersonel,
  getPersonel,
  getRoles,
  getSettingsNotification,
  enableMultifactorauthentication,
  disableMultifactorauthentication,
  updateSettingsNotification,
};
