import useFetch from "hooks/useFetch";
import React, { createContext, useContext, useState } from "react";
import { updateBusinessService } from "services/business";

const BusinessContext = createContext<any>(undefined);

export const BusinessProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [businessData, setBusinessData] = useState<any>({});
  const [business, setBusiness] = useState<any>({});
  const [documents, setDocuments] = useState(business?.documents || []);
  const [filesData, setFilesData] = useState<Record<string, any>>({
    NDA: null,
    SLA: null,
    ISO: null,
    PCI: null,
  });
  const {
    data: updateBusinessData,
    error: updateBusinessError,
    loading: updateBusinessLoading,
    fetchData: updateBusinessFetchData,
  } = useFetch<any>();

  const transformData = (businessData: any) => {
    return {
      companyName: businessData.companyName,
      companyEmail: businessData.companyEmail,
      companyDomain: businessData.companyDomain,
      vegeelAppModules: (businessData.appModules || []).map(
        (module: any) => module.publicId
      ),
      vegeelFrameworks: (businessData.frameworks || []).map((framework: any) =>
        framework ? framework.publicId : null
      ),
      businessStatus: businessData.businessStatus || "ENABLED",
      appAccess: businessData.appAccess || "FULL_ACCESS",
      documents: Array.isArray(businessData.documents)
        ? businessData.documents.map((doc: any) => ({
            type: doc.type,
            fileType: doc.fileType,
            fileName: doc.fileName,
            url: doc.url,
          }))
        : [],
      subscriptionType: businessData.subscriptionType || "PAID", // Default to "PAID" if not provided
      tenantAdmins: Array.isArray(businessData.tenantAdmins)
        ? businessData.tenantAdmins.map((admin: any) => ({
            email: admin.email,
            firstName: admin.firstName,
            lastName: admin.lastName,
          })) // Only send necessary fields
        : [],
      customerSuccessManager: businessData.customerSuccessManager,
      salesRep: businessData.salesRep,
      subscription: {
        publicId: businessData.subscription?.publicId,
        subscriptionAmount: businessData.subscription?.subscriptionAmount || 0, // Default value
        creationDate: businessData.subscription?.creationDate,
        expirationDate: businessData.subscription?.expirationDate,
        duration: businessData.subscription?.duration,
      },
    };
  };

  const transformedData = transformData(businessData);

  const handleUpdate = (id: any) => {
    updateBusinessFetchData(() => updateBusinessService(id, transformedData));
  };

  return (
    <BusinessContext.Provider
      value={{
        business,
        businessData,
        setBusinessData,
        setBusiness,
        handleUpdate,
        updateBusinessData,
        updateBusinessError,
        updateBusinessLoading,
        setDocuments,
        documents,
        setFilesData,
        filesData,
        transformData,
        transformedData,
      }}
    >
      {children}
    </BusinessContext.Provider>
  );
};

export const useBusinessContext = (): any => {
  const context = useContext(BusinessContext);
  if (!context) {
    throw new Error("useBusinessContext must be used within an AppProvider");
  }
  return context;
};
