import {
  ReactNode,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";

export interface HeaderContextType {
  headerTitle: string;
  setHeaderTitle: Dispatch<SetStateAction<string>>;
}

export const HeaderContext = createContext<HeaderContextType | undefined>(
  undefined
);

export const HeaderProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [headerTitle, setHeaderTitle] = useState<string>("");

  return (
    <HeaderContext.Provider value={{ headerTitle, setHeaderTitle }}>
      {children}
    </HeaderContext.Provider>
  );
};
