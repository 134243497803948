import React, { useState, Dispatch, SetStateAction } from "react";
import { Upload, message, Spin } from "antd";
import CustomIcon from "components/custom-icon";
import { ReactComponent as uploadIcon } from "../../../assets/icons/base.svg";
import { ReactComponent as deleteIcon } from "../../../assets/icons/delete-icon-red.svg";
import useFetch from "hooks/useFetch";
import { UploadBlogUrl } from "services/blog";

const { Dragger } = Upload;

interface BlogImageUploaderProps {
  imageUrl: string | null;
  setImageUrl: Dispatch<SetStateAction<string | null>>;
  setImageData: any;
  imageData: any;
}

interface IDataResponse {
  isSuccess: boolean;
  isError: boolean;
  description: string;
  status: string;
  data: {
    secure_url: string;
  };
}

const BlogImageUploader: React.FC<BlogImageUploaderProps> = ({
  imageUrl,
  setImageUrl,
  setImageData,
  imageData,
}) => {
  const [imageName, setImageName] = useState<string | null>(null);
  const { error, loading, fetchData, data } = useFetch<IDataResponse>();

  const validateFile = (file: File) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG files!");
      return false;
    }

    const isLt800x600 = new Promise<boolean>((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const { width, height } = img;
        if (width <= 800 && height <= 600) {
          resolve(true);
        } else {
          message.error("Image must be smaller than 800x600px!");
          resolve(false);
        }
      };
    });

    return isLt800x600;
  };

  const props = {
    name: "file",
    multiple: false,
    beforeUpload: async (file: File) => {
      const isValid = await validateFile(file);
      if (isValid) {
        const fileNameParts = file.name.split(".");
        const newFileName =
          fileNameParts.slice(0, -1).join(".") + "_." + fileNameParts.pop();
        const modifiedFile = new File([file], newFileName, {
          type: file.type,
        });

        // console.log(modifiedFile);
        const formData = new FormData();
        formData.append("file", modifiedFile);

        setImageData(modifiedFile);

        const reader = new FileReader();
        reader.onload = (e) => {
          setImageUrl(e.target?.result as string);
          setImageName(modifiedFile.name);
        };
        reader.readAsDataURL(modifiedFile);
      }
      return false;
    },
    onDrop(e: React.DragEvent<HTMLDivElement>) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const removeImage = () => {
    setImageUrl(null);
    setImageName(null);
    if (imageData) {
      URL.revokeObjectURL(imageData); // Clean up the object URL
      setImageData(null);
    }
  };

  return (
    <div className="w-full">
      <h1 className="font-[400] text-[14px] leading-[24px] text-[#455468] flex gap-1">
        Thumbnail
        <span className="text-[#042BB2]">*</span>
      </h1>
      <Dragger
        {...props}
        style={{
          backgroundColor: "#ffffff",
          border: "1px solid #D7DFE9",
          borderRadius: "6px",
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundRepeat: "no-repeat",
        }}
        showUploadList={false}
      >
        {loading ? (
          <div className="flex justify-center items-center h-[150px]">
            <Spin size="large" />
          </div>
        ) : imageUrl ? (
          <div
            style={{
              backgroundImage: `url(${imageUrl})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
            className="relative h-[150px] w-full object-cover"
          ></div>
        ) : (
          <div className="h-[150px] w-full flex justify-center flex-col items-center">
            <p className="flex justify-center py-3">
              <CustomIcon SvgIcon={uploadIcon} size={50} />
            </p>
            <p className="text-[14px]">
              <span className="text-[#1B4DFF] font-[700]">Click to upload</span>{" "}
              or drag and drop
            </p>
            <p className="ant-upload-hint font-[400] text-[14px]">
              PNG or JPEG (max. size 800 X 600px)
            </p>
          </div>
        )}
      </Dragger>
      {imageUrl && !loading && (
        <div className="flex gap-2 mt-2  w-full justify-between flex-row-reverse items-center">
          <div
            onClick={removeImage}
            className="flex items-center gap-2 text-[#CB1A14] text-[14px] font-[400] cursor-pointer "
          >
            <CustomIcon SvgIcon={deleteIcon} size={20} />
            Remove
          </div>
          <div className="text-[#455468] text-[14px]">{imageName}</div>
        </div>
      )}
    </div>
  );
};

export default BlogImageUploader;
