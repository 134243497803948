import { BlogProvider } from "context/blog";
import { Outlet } from "react-router-dom";

export function BlogWrapper({ children }: any) {
  return (
    <main className="h-full overflow-y-scroll ">
      {children ? children : <Outlet />}
    </main>
  );
}

export default function Blog() {
  return (
    <BlogProvider>
      <BlogWrapper />
    </BlogProvider>
  );
}
