import { useHeaderContext } from "hooks/useHeader";
import { useEffect, useState } from "react";
import arrowLeft from "../../../../assets/icons/arrow-left.svg";
import BlogEditor from "components/blog/ediitor";
import BlogInput from "components/blog/input";
import BlogImageUploader from "components/blog/uploader";
import Selector from "components/blog/selector";
import CustomIcon from "components/custom-icon";
import { ReactComponent as plusIncon } from "../../../../assets/icons/plus-icon-blue.svg";
import { ReactComponent as deleteIcon } from "../../../../assets/icons/x-small.svg";
import { useNavigate } from "react-router-dom";
import { getTokenFromLocalStore, saveTokenToLocalStore } from "utils";

interface SubtopicOptions {
  value: string;
  index: number;
  cancel: () => void;
}
export function SubTopic({ value, index, cancel }: SubtopicOptions) {
  return (
    <div className="flex w-full justify-between items-center py-2">
      <div className="flex items-center gap-2 text-[14px] font-[400] text-[#111111]  w-[80%] justify-start truncate overflow-hidden whitespace-nowrap">
        <span>{index + 1}.</span>
        <span className="truncate">{value}</span>
      </div>
      <div
        onClick={cancel}
        className="underline flex items-center gap-2 text-[14px] font-[400] text-[#455468]"
      >
        Clear
        <CustomIcon SvgIcon={deleteIcon} size={20} />
      </div>
    </div>
  );
}

export default function EditBlog() {
  const { setHeaderTitle } = useHeaderContext();
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [subtopics, setSubtopics] = useState<string>("");
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [subBlogTopics, setSubBlogTopics] = useState<string[]>([]);
  const [disabledBtn, setdisAbledBtn] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [imageData, setImageData] = useState<string | null>(null);

  const data: any = getTokenFromLocalStore("editData");
  console.log(data);

  function handleInputChange(e: any) {
    setSubtopics(e.target.value);
  }

  const extractTextFromHtml = (htmlContent: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    return doc.body.textContent || "";
  };

  function handlePreview(e: any) {
    const body = {
      title: title,
      content: extractTextFromHtml(value),
      author: "Vegeel",
      category: selectedCategory,
      subTopics: subBlogTopics,
      image: imageUrl,
      visibility: "string",
      id: data.publicId,
      published: data.published,
    };
    console.log(body);
    saveTokenToLocalStore("blogPrev1ew", body);
    saveTokenToLocalStore("editData", body);
    saveTokenToLocalStore("comingFormEdit", "yes");
    navigate("/blog/preview-blog");
  }

  function backtoBlogScreen() {
    localStorage.removeItem("blogPrev1ew");
    localStorage.removeItem("editData");
    navigate("/blog");
  }
  useEffect(() => {
    if (data) {
      setTitle(data.title);
      setValue(data.content);
      setSelectedCategory(data.category);
      setSubBlogTopics(data.subTopics);
      setImageUrl(data.image || imageUrl);
    }
  }, []);

  function addTopic() {
    if (subtopics) {
      setSubBlogTopics((prevTopics) => [...prevTopics, subtopics]);
      setSubtopics("");
    }
  }

  function removeSubtopic(index: number) {
    setSubBlogTopics((prevTopics) => prevTopics.filter((_, i) => i !== index));
  }

  function handleTitle(e: any) {
    setTitle(e.target.value);
  }
  useEffect(() => {
    setHeaderTitle("Blog");
  }, [setHeaderTitle]);

  return (
    <div className="h-full overflow-y-scroll  flex flex-col gap-4">
      <p>edit blog</p>
      <div className="flex justify-between w-full items-center">
        <div className="flex flex-col">
          <h1 className="text-[18px] font-[500] leading-[30px] text-[#111111]">
            Upload new blog
          </h1>
          <p className="text-[#455468] text-[14px] font-[400]">
            Complete the process to upload a new blog.
          </p>
        </div>

        <div className="">
          <button
            onClick={backtoBlogScreen}
            className="py-[8px] px-[15px] bg-[white] rounded-[6px] flex justify-center items-center gap-[10px] border"
          >
            <img src={arrowLeft} alt="" />
            <h1 className="font-[500] text-[14px] leading-[24px] text-[#455468]">
              Back to blog post
            </h1>
          </button>
        </div>
      </div>

      <div className="flex w-full h-full bg-white p-3 gap-6 overflow-hidden">
        <div className=" h-[85%] w-[60%] flex flex-col gap-3">
          <BlogInput
            label="Title"
            placeholder="Enter a blog title"
            required
            type="text"
            onChange={handleTitle}
            value={title}
          />

          <BlogEditor setValue={setValue} value={value} />
        </div>

        <div className="w-[40%] p-3 border-l-2 flex flex-col h-[100%] gap-4 overflow-y-scroll">
          <div>
            <BlogImageUploader
              imageData={imageData}
              setImageData={setImageData}
              imageUrl={imageUrl}
              setImageUrl={setImageUrl}
            />
          </div>
          <div>
            <Selector
              setSelectedOptions={setSelectedCategory}
              selectedOptions={selectedCategory}
            />
          </div>

          <div>
            <BlogInput
              label="Subtopics (optional)"
              placeholder="Enter subtopic"
              required={false}
              type="text"
              value={subtopics}
              onChange={handleInputChange}
            />
          </div>
          <div
            onClick={addTopic}
            className="text-[#1B4DFF] text-[14px] font-[500] flex items-center gap-2 justify-end cursor-pointer"
          >
            <CustomIcon SvgIcon={plusIncon} size={26} />
            Add topic
          </div>

          <div>
            {subBlogTopics.map((value, index) => (
              <SubTopic
                key={index}
                index={index}
                value={value}
                cancel={() => removeSubtopic(index)}
              />
            ))}
          </div>

          <div className="flex gap-4 items-center flex-col-reverse justify-between py-3 px-3">
            {/* <button
              // onClick={handlePreview}
              className="flex flex-row justify-center items-center py-[8px] px-[15px] gap-2.5 w-full  bg-white border border-slate-300 shadow-[0_6px_15px_-2px_rgba(162,181,218,0.15),0_2px_8px_-4px_rgba(16,24,40,0.05)] rounded-md"
            >
              Save as Draft
            </button> */}
            <button
              onClick={handlePreview}
              // disabled={disabledBtn}
              className={`py-[8px] px-[15px]  ${
                disabledBtn
                  ? "bg-[#1B4DFF] opacity-50 cursor-not-allowed"
                  : "bg-[#1B4DFF]"
              }  border border-[#1B4DFF] rounded-[6px] flex justify-center items-center gap-[10px] text-white w-full`}
              // onClick={handleSaveChanges}
            >
              <h1 className="font-[500] text-[14px] leading-[24px]">
                Preview blog
              </h1>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
