import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HeaderProvider } from "context/home/header";
import { Toaster } from "sonner";
import { AppProvider } from "context/home/app";
import { BusinessProvider } from "context/business";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BusinessProvider>
      <AppProvider>
        <HeaderProvider>
          <Toaster position="top-right" richColors duration={2000} />
          <App />
        </HeaderProvider>
      </AppProvider>
    </BusinessProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
