import { Checkbox, Space, Switch } from "antd";
import CustomSelect from "components/settings/custom-select";
import { useSettings } from "context/settings";
import { useEffect, useState } from "react";

export default function SettingsNotifications() {
  const { notificationsSettingData, setNotificationBody } = useSettings();

  const [settings, setSettings] = useState({
    pushBusinessAndSubscriptionToApp:
      notificationsSettingData.pushBusinessAndSubscriptionToApp,
    pushBusinessAndSubscriptionToMail:
      notificationsSettingData.pushBusinessAndSubscriptionToMail,
    newBusinessOnboarded: notificationsSettingData.newBusinessOnboarded,
    frequencyOfNotification: notificationsSettingData.frequency,
    assignedTeamMemberToApp: notificationsSettingData.assignedTeamMemberToApp,
    assignedTeamMemberToMail: notificationsSettingData.assignedTeamMemberToMail,
    assignedResponsibility: notificationsSettingData.assignedResponsibility,
    subscriptionExpiration: notificationsSettingData.subscriptionExpiration,
    changesMadeToControl: notificationsSettingData.changesMadeToControl,
    reminderToApp: notificationsSettingData.reminderToApp,
    reminderToMail: notificationsSettingData.reminderToMail,
    moreActivityToApp: notificationsSettingData.moreActivityToApp,
    moreActivityToMail: notificationsSettingData.moreActivityToMail,
  });

  useEffect(() => {
    setSettings({
      pushBusinessAndSubscriptionToApp:
        notificationsSettingData.pushBusinessAndSubscriptionToApp,
      pushBusinessAndSubscriptionToMail:
        notificationsSettingData.pushBusinessAndSubscriptionToMail,
      newBusinessOnboarded: notificationsSettingData.newBusinessOnboarded,
      frequencyOfNotification: notificationsSettingData.frequency,
      assignedTeamMemberToApp: notificationsSettingData.assignedTeamMemberToApp,
      assignedTeamMemberToMail:
        notificationsSettingData.assignedTeamMemberToMail,
      assignedResponsibility: notificationsSettingData.assignedResponsibility,
      subscriptionExpiration: notificationsSettingData.subscriptionExpiration,
      changesMadeToControl: notificationsSettingData.changesMadeToControl,
      reminderToApp: notificationsSettingData.reminderToApp,
      reminderToMail: notificationsSettingData.reminderToMail,
      moreActivityToApp: notificationsSettingData.moreActivityToApp,
      moreActivityToMail: notificationsSettingData.moreActivityToMail,
    });
  }, [notificationsSettingData]);

  console.log("notificationsSettingData", notificationsSettingData);

  useEffect(() => {
    setNotificationBody({
      ...settings,
      frequencyOfNotification: 0, // You can replace this with your desired frequency logic
    });
  }, [settings]);

  const handleChange = (key: keyof typeof settings) => (value: any) => {
    setSettings((prev) => ({
      ...prev,
      [key]: typeof value === "boolean" ? value : value.target.checked,
    }));
  };

  const handleFrequency = (value: string) => {
    setSettings((prev) => ({ ...prev, frequency: value }));
  };

  const option = [
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Annually", label: "Annually" },
  ];

  return (
    <div>
      <div className="flex flex-col">
        {/* Business & Subscription Section */}
        <section className="flex gap-[60px] border-b pb-[16px]">
          <div className="w-[25%]">
            <h1 className="font-[500] text-[14px] leading-[24px]">
              Business & Subscription
            </h1>
            <h1 className="text-[#455468] text-[14px] leading-[24px] font-[400]">
              These are notifications to inform you about the activities of a
              business.
            </h1>
          </div>
          <div>
            <div>
              <h1 className="font-[500] text-[14px] leading-[24px]">Alerts</h1>
              <div className="flex flex-col gap-[12px]">
                <Space>
                  <Switch
                    checked={settings.pushBusinessAndSubscriptionToApp}
                    onChange={handleChange("pushBusinessAndSubscriptionToApp")}
                  />
                  Push
                </Space>
                <Space>
                  <Switch
                    checked={settings.pushBusinessAndSubscriptionToMail}
                    onChange={handleChange("pushBusinessAndSubscriptionToMail")}
                  />
                  Email
                </Space>
              </div>
            </div>
            <div className="pt-[24px]">
              <h1 className="font-[500] text-[14px] leading-[24px]">
                Alert me when
              </h1>
              <div className="pt-[6px] flex flex-col gap-[12px]">
                <Space>
                  <Checkbox
                    checked={settings.newBusinessOnboarded}
                    onChange={handleChange("newBusinessOnboarded")}
                  />
                  <label className="font-[500] text-[14px] leading-[24px]">
                    A new business has onboarded
                  </label>
                </Space>
                <Space>
                  <Checkbox
                    checked={settings.subscriptionExpiration}
                    onChange={handleChange("subscriptionExpiration")}
                  />
                  <label className="font-[500] text-[14px] leading-[24px]">
                    A business subscription is about to expire
                  </label>
                </Space>
              </div>
            </div>
            <div className="pt-[24px]">
              <CustomSelect
                placeholder="Set frequency"
                label="Set frequency for subscription expiration"
                options={option}
                value={settings.frequencyOfNotification}
                onChange={handleFrequency}
              />
            </div>
          </div>
        </section>

        {/* Assign Team Members Section */}
        <section className="flex gap-[60px] border-b py-[16px]">
          <div className="w-[25%]">
            <h1 className="font-[500] text-[14px] leading-[24px]">
              Assign team members
            </h1>
            <h1 className="text-[#455468] text-[14px] leading-[24px] font-[400]">
              These are notifications to inform you about activities/changes
              made within a control.
            </h1>
          </div>
          <div>
            <div>
              <h1 className="font-[500] text-[14px] leading-[24px]">Alerts</h1>
              <div className="flex flex-col gap-[12px]">
                <Space>
                  <Switch
                    checked={settings.assignedTeamMemberToApp}
                    onChange={handleChange("assignedTeamMemberToApp")}
                  />
                  Push
                </Space>
                <Space>
                  <Switch
                    checked={settings.assignedTeamMemberToMail}
                    onChange={handleChange("assignedTeamMemberToMail")}
                  />
                  Email
                </Space>
              </div>
            </div>
            <div className="pt-[24px]">
              <h1 className="font-[500] text-[14px] leading-[24px]">
                Alert me when
              </h1>
              <div className="pt-[6px] flex flex-col gap-[12px]">
                <Space>
                  <Checkbox
                    checked={settings.assignedResponsibility}
                    onChange={handleChange("assignedResponsibility")}
                  />
                  <label className="font-[500] text-[14px] leading-[24px]">
                    Assigned responsibility
                  </label>
                </Space>
                <Space>
                  <Checkbox
                    checked={settings.changesMadeToControl}
                    onChange={handleChange("changesMadeToControl")}
                  />
                  <label className="font-[500] text-[14px] leading-[24px]">
                    Changes are made to a control
                  </label>
                </Space>
              </div>
            </div>
          </div>
        </section>

        {/* Reminders Section */}
        <section className="flex gap-[60px] border-b py-[16px]">
          <div className="w-[25%]">
            <h1 className="font-[500] text-[14px] leading-[24px]">Reminders</h1>
            <h1 className="text-[#455468] text-[14px] leading-[24px] font-[400]">
              These are notifications to remind you of updates you might have
              missed.
            </h1>
          </div>
          <div>
            <div className="flex flex-col gap-[12px]">
              <Space>
                <Switch
                  checked={settings.reminderToApp}
                  onChange={handleChange("reminderToApp")}
                />
                Push
              </Space>
              <Space>
                <Switch
                  checked={settings.reminderToMail}
                  onChange={handleChange("reminderToMail")}
                />
                Email
              </Space>
            </div>
          </div>
        </section>

        {/* More Activity Section */}
        <section className="flex gap-[60px] border-t border-b py-[16px] mb-4">
          <div className="w-[25%]">
            <h1 className="font-[500] text-[14px] leading-[24px]">
              More activity about you
            </h1>
            <h1 className="text-[#455468] text-[14px] leading-[24px] font-[400]">
              These are notifications for updates
            </h1>
          </div>
          <div>
            <div className="flex flex-col gap-[12px]">
              <Space>
                <Switch
                  checked={settings.moreActivityToApp}
                  onChange={handleChange("moreActivityToApp")}
                />
                Push
              </Space>
              <Space>
                <Switch
                  checked={settings.moreActivityToMail}
                  onChange={handleChange("moreActivityToMail")}
                />
                Email
              </Space>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
