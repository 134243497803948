import React, { useState } from "react";
import CloseIcon from "../xIcon";
import CustomIcon from "components/custom-icon";
import { useNavigate } from "react-router-dom";
import { saveTokenToLocalStore } from "utils";
import DropSelect from "../droppDown";
import DeleteBLogModal from "../modal/delete";
import { useBlog } from "context/blog";

interface IContent {
  createdDate: string;
  publicId: string;
  createdBy: string;
  title: string;
  content: string;
  author: string;
  category: string[];
  subTopics: string[];
  image: string;
  visibility: string;
  published: boolean;
  dateAdded: string;
}

interface IDataContent {
  content: IContent[];
}

const Category: React.FC<{ value: string }> = ({ value }) => {
  const { borderColor, bgColor, textColor } = getStyle(value);
  return (
    <div
      className="flex items-center rounded-[6px] py-[8px] px-[4px] gap-2"
      style={{
        border: `1px solid ${borderColor}`,
        backgroundColor: bgColor,
        color: textColor,
      }}
    >
      <span className="text-[14px] font-[500] capitalize">{value}</span>
    </div>
  );
};

const styles: {
  [key: string]: { borderColor: string; bgColor: string; textColor: string };
} = {
  FRAMEWORK: {
    borderColor: "#EA6F03",
    bgColor: "#FFFAEB",
    textColor: "#EA6F03",
  },
  updates: {
    borderColor: "#455468",
    bgColor: "#FAFAFA",
    textColor: "#455468",
  },
  CYBERSECURITY: {
    borderColor: "#027A48",
    bgColor: "#ECFDF3",
    textColor: "#027A48",
  },
  RISK_ASSESSMENT: {
    borderColor: "#1B4DFF",
    bgColor: "#F2F5FF",
    textColor: "#1B4DFF",
  },
  "news and events": {
    borderColor: "#CB1A14",
    bgColor: "#FFF5F4",
    textColor: "#CB1A14",
  },
  default: {
    borderColor: "#000",
    bgColor: "#FFF",
    textColor: "#000",
  },
};

// Update the getStyle function to handle unknown categories
const getStyle = (value: string) => {
  return styles[value] || styles.default;
};

export default function BlogCard({ data }: { data: any[] }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState<any>();
  const { setPreview, setComingFromDraft } = useBlog();

  function onDeleteClick() {
    setOpen(true);
  }

  function onEditClick(id: any) {
    saveTokenToLocalStore("editData", id);
    navigate(`/blog/edit-blog/${id.publicId}`);
  }

  function onPreviewClick(id: any) {
    if (id?.published) {
      setPreview(true);
      setComingFromDraft(false);
      saveTokenToLocalStore("comingfromDraft", "no");
    } else {
      setComingFromDraft(true);
      saveTokenToLocalStore("comingfromDraft", "yes");
      setPreview(false);
    }
    saveTokenToLocalStore("blogPrev1ew", id);
    navigate("/blog/preview-blog");
  }

  return (
    <>
      {data.map((item: any, index: number) => (
        <div
          key={index}
          className="bg-[#FFFFFF] flex flex-col gap-[1rem] rounded-[6px] p-[1rem] shadow-sm w-full"
        >
          <div className="flex justify-between items-center">
            {item.published ? (
              <Category value={item.category?.[0] || "default"} />
            ) : (
              <div className="text-[#a5a8ac] bg-[#fafafa]  border-[1px] border-[#a5a8ac]  rounded-[6px] py-[8px] px-[14px]">
                Draft
              </div>
            )}

            <div className="cursor-pointer">
              <DropSelect
                onDeleteClick={() => onDeleteClick()}
                onEditClick={() => onEditClick(item)}
                onPreviewClick={() => onPreviewClick(item)}
              />
            </div>
          </div>

          <div className="h-[150px] w-full">
            <img
              src={item?.image}
              alt="image"
              className="w-full h-full rounded-[6px] object-cover"
            />
          </div>

          <div className="text-[18px] font-[500] text-[#111111] capitalize">
            {item?.title || "No title available"}
          </div>

          <div className="text-[#455468] text-[400] text-[14px] truncate">
            {item?.content || "No content available"}
          </div>

          <hr className="border" />

          <div className="text-[#455468] text-[400] text-[12px]">
            <span className="text-[14px] text-[#111111]">Posted on: </span>
            {item?.createdDate || "No date available"}
          </div>
          <DeleteBLogModal id={item.publicId} open={open} setOpen={setOpen} />
        </div>
      ))}
    </>
  );
}
