import dayjs, { Dayjs } from "dayjs";

export function getInitials(fullName: string) {
  if (typeof fullName !== "string") {
    return "";
  }

  let names = fullName.split(/[_\s]+/);
  let firstInitial = names[0][0];
  let lastInitial = names.length > 1 ? names[names.length - 1][0] : "";
  return firstInitial + (lastInitial ? " " + lastInitial : "");
}

export function formatDate(dateString: string): string {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return date.toLocaleDateString("en-US", options);
}

export const removeDuplicates = (arr: any[]) => {
  const seen = new Set();
  return arr.filter((item: { label: unknown }) => {
    if (!seen.has(item.label)) {
      seen.add(item.label);
      return true;
    }
    return false;
  });
};

export const formatBusinessDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0];
};

export const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + "...";
};

export const formatTime = (time: Dayjs | null) => {
  if (time && dayjs.isDayjs(time)) {
    return time.format("HH:mm:ss.SSS");
  }
  return null;
};

export const findRolePublicId = (
  roleData: any[],
  personnelData: { roleName: any }
) => {
  const matchingRole = roleData.find(
    (role: { name: any }) => role.name === personnelData.roleName
  );
  return matchingRole ? matchingRole.publicId : null;
};
