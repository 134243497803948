import React from "react";
import { Dropdown, MenuProps } from "antd";
import CustomIcon from "components/custom-icon";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as EditPenIcon } from "assets/icons/edit-black.svg";
import { ReactComponent as TrashIcon } from "assets/icons/delete-icon-red.svg";
import { ReactComponent as DotsIcon } from "assets/icons/dots.svg";

interface DropSelectProps {
  onPreviewClick: () => void;
  onEditClick: () => void;
  onDeleteClick: () => void;
}

const DropSelect: React.FC<DropSelectProps> = ({
  onPreviewClick,
  onEditClick,
  onDeleteClick,
}) => {
  const items: MenuProps["items"] = [
    {
      label: (
        <button
          onClick={onPreviewClick}
          className="pl-2 pr-[5rem] text-sm font-medium text-[#455468]"
        >
          Preview
        </button>
      ),
      icon: <CustomIcon SvgIcon={EyeIcon} size={16} />,
      key: "0",
    },
    {
      label: (
        <button
          onClick={onEditClick}
          className="pl-2 pr-[5rem] text-sm font-medium text-[#455468]"
        >
          Edit
        </button>
      ),
      icon: <CustomIcon SvgIcon={EditPenIcon} size={16} />,
      key: "1",
    },
    {
      label: (
        <button
          onClick={onDeleteClick}
          className="pl-2 pr-[5rem] text-sm font-medium text-[#CB1A14]"
        >
          Delete
        </button>
      ),
      icon: <CustomIcon SvgIcon={TrashIcon} size={16} />,
      key: "2",
    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={["click"]} placement="bottomRight">
      <button className="">
        <CustomIcon SvgIcon={DotsIcon} size={16} />
      </button>
    </Dropdown>
  );
};

export default DropSelect;
