import apiService from "./api";

const baseurl = process.env.REACT_APP_BASE_URL;

const createBlog = async (body: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService("/blog", "post", body, true, headers, undefined, baseurl);
};
const getBlogs = async () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService(
    "/blog",
    "get",
    undefined,
    true,
    headers,
    undefined,
    baseurl
  );
};
const updateBlog = async (id: any, body: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService(
    `/blog/${id}`,
    "put",
    body,
    true,
    headers,
    undefined,
    baseurl
  );
};
const deleteBlog = async (id: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService(
    `/blog/${id}`,
    "delete",
    undefined,
    true,
    headers,
    undefined,
    baseurl
  );
};
const getDraftBlogs = async () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService(
    "/blog/drafts",
    "get",
    undefined,
    true,
    headers,
    undefined,
    baseurl
  );
};
const UploadBlogUrl = async (file: any) => {
  const headers = {};
  return apiService(
    "/utils/files/upload",
    "post",
    file,
    true,
    headers,
    {},
    baseurl
  );
};

export {
  createBlog,
  getBlogs,
  UploadBlogUrl,
  getDraftBlogs,
  updateBlog,
  deleteBlog,
};
