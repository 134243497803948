import React, { useState, useEffect } from "react";
import { Select } from "antd";

const { Option } = Select;

interface OptionType {
  value: any;
  label: any;
}

interface CustomSelectProps {
  id?: string;
  placeholder?: string;
  value?: string;
  label?: string;
  // desc?: string;
  options?: OptionType[];
  required?: boolean;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  error?: string | null;
  touched?: boolean;
  defaultValue?: string;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  id,
  placeholder,
  label,

  value,
  options,
  required,
  onChange,
  onBlur,
  error,
  touched,
  defaultValue,
}) => {
  // const [selectedValue, setSelectedValue] = useState<string | undefined>(value);

  // useEffect(() => {
  //   if (value !== selectedValue) {
  //     setSelectedValue(value);
  //   }
  // }, [value]);

  // const handleChange = (value: string) => {
  //   setSelectedValue(value);

  // };

  return (
    <div className="">
      <div className="">
        <div className="">
          <div className="font-[400] text-[14px] leading-[24px] pb-[8px] text-[#455468] flex gap-1">
            <h1>
              {label}
              {required && <span className="text-primaryblue"> *</span>}
            </h1>
            {/* <p className="text-xs">{desc}</p> */}
          </div>
        </div>
        <Select
          id={id}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          size="large"
          className={` w-full ${error && touched ? "border-red-500" : ""}`}
        >
          {options?.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default CustomSelect;
