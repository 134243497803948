import { Dropdown, Menu, MenuProps } from "antd";
import { ReactComponent as IconBell } from "assets/icons/Iconbell.svg";
import { ReactComponent as IconDrowdown } from "assets/icons/IconDropdown.svg";
import { ReactComponent as IconLock } from "assets/icons/IconLock.svg";
import { ReactComponent as IconLogout } from "assets/icons/IconLogout.svg";
import { ReactComponent as IconUser } from "assets/icons/IconUserProfile.svg";
import { ReactComponent as max } from "assets/icons/maximise-01.svg";
import { ReactComponent as close } from "assets/icons/x-01.svg";
import avatar from "assets/images/Avatar2.png";
import CustomIcon from "components/custom-icon";
import { useAppContext } from "context/home/app";
import { useHeaderContext } from "hooks/useHeader";
import useModal from "hooks/useModal";
import { Link, useLocation } from "react-router-dom";
import vegeelIcon from "../../assets/images/vegeel-blue.svg";
import PasswordModal from "../loader/password-modal";
import LogoutModal from "../modals/logout-modal";
import NotifyItems from "./notify-items";
import ProfileModal from "./profle-modal";

export default function Header() {
  const { headerTitle } = useHeaderContext();
  const { pathname } = useLocation();
  const { getUserInfo } = useAppContext();

  const userInfo = getUserInfo();

  const {
    open: open1,
    loading: loading1,
    showModal: showModal1,
    handleOk: handleOk1,
    handleCancel: handleCancel1,
  } = useModal();
  const {
    open: open2,
    loading: loading2,
    showModal: showModal2,
    handleOk: handleOk2,
    handleCancel: handleCancel2,
  } = useModal();
  const {
    open: open3,
    loading: loading3,
    showModal: showModal3,
    handleOk: handleOk3,
    handleCancel: handleCancel3,
  } = useModal();

  const notifications = [
    {
      title: "Olamide left a comment on Site redesign",
      date: "Thurs 21, 2023",
      audit: "Audit",
      avatar: avatar,
      pdf: {
        name: "Meeting minute",
        size: "13MB",
        type: "PDF File",
        date: "11 Sept 23",
      },
    },
    // {
    //   title: "John left a comment on Site redesign",
    //   date: "Thurs 21, 2023",
    //   audit: "Audit",
    //   avatar: avatar,
    //   pdf: {
    //     name: "Meeting minute",
    //     size: "13MB",
    //     type: "PDF File",
    //     date: "11 Sept 23",
    //   },
    // },
    // {
    //   title: "Jane left a comment on Site redesign",
    //   date: "Thurs 21, 2023",
    //   audit: "Audit",
    //   avatar: avatar,
    //   pdf: {
    //     name: "Meeting minute",
    //     size: "13MB",
    //     type: "PDF File",
    //     date: "11 Sept 23",
    //   },
    // },
  ];

  const items: MenuProps["items"] = [
    {
      label: (
        <div className="py-1 pr-16" onClick={showModal1}>
          <span className="font-medium pl-1 leading-[24px] text-sm text-[#455468]">
            View Profile
          </span>
        </div>
      ),
      icon: <CustomIcon SvgIcon={IconUser} size={16} />,
      key: "0",
    },
    {
      label: (
        <div className="py-1 pl-1 pr-16" onClick={showModal2}>
          <span className="font-medium leading-[24px]  text-sm text-[#455468]">
            Change Password
          </span>
        </div>
      ),
      icon: <CustomIcon SvgIcon={IconLock} size={16} />,
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div
          className="flex items-center justify-between w-full py-1 px-2"
          onClick={showModal3}
        >
          <span className="font-medium leading-[24px]  text-sm text-[#CB1A14]">
            Logout
          </span>
          <p className="text-[#667085] text-xs">v1.0</p>
        </div>
      ),
      icon: <CustomIcon SvgIcon={IconLogout} size={16} />,
      key: "2",
    },
  ];

  const items1: MenuProps["items"] = [
    {
      label: (
        <div className="w-full flex items-center justify-between">
          <span className="font-medium pl-1 leading-[24px] text-sm text-[#455468]">
            Notifications
          </span>

          <div className="flex items-center gap-2">
            <p className="text-xs text-[#1B4DFF] font-medium rounded-md px-2 bg-[#E5EAFC]">
              0 unread
            </p>
            <Link to="/notifications">
              <div>
                <CustomIcon SvgIcon={max} size={16} />
              </div>
            </Link>
            <button onClick={() => console.log("Close dropdown")}>
              <div>
                <CustomIcon SvgIcon={close} size={16} />
              </div>
            </button>
          </div>
        </div>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    ...notifications.map((notification, index) => ({
      key: `notification-${index}`,
      label: (
        <Menu.Item
          key={index}
          style={{
            background: "transparent",
          }}
        >
          <NotifyItems
            avatar={notification.avatar}
            title={notification.title}
            audit={notification.audit}
            pdf={notification.pdf}
            date={notification.date}
          />
        </Menu.Item>
      ),
    })),
  ];

  return (
    <header className="h-[70px] w-full flex items-center justify-between px-10 border-b border-[#D7DFE9]">
      {!pathname.includes("preview-blog") ? (
        <h1 className="text-[24px] font-medium leading-[36px] tracking-[0.4px] text-[#111111]">
          {headerTitle}
        </h1>
      ) : (
        <img src={vegeelIcon} alt="vegeel blue" className="w-[114px]" />
      )}
      <div className="h-[36px] flex justify-between items-center gap-4">
        <Dropdown menu={{ items: items1 }} trigger={["click"]}>
          <div className="h-full w-[36px] bg-[#E5EAFC] flex justify-center items-center rounded-md cursor-pointer">
            <CustomIcon SvgIcon={IconBell} size={18} />
          </div>
        </Dropdown>

        <Dropdown menu={{ items }} trigger={["click"]}>
          <div className="py-[4px] px-[10px] bg-[#F2F5FF] border border-[#E5EAFC] rounded-[100px] flex items-center gap-1 cursor-pointer">
            <img
              src={
                userInfo.profilePicture ||
                "https://i.pinimg.com/474x/16/3e/39/163e39beaa36d1f9a061b0f0c5669750.jpg"
              }
              alt="Emmanuel igwenagu"
              className="w-[32px] h-[32px] rounded-full object-cover"
            />
            <CustomIcon SvgIcon={IconDrowdown} size={10} />
          </div>
        </Dropdown>
      </div>

      <ProfileModal
        open={open1}
        loading={loading1}
        handleOk={handleOk1}
        handleCancel={handleCancel1}
      />

      <PasswordModal
        open={open2}
        loading={loading2}
        handleOk={handleOk2}
        handleCancel={handleCancel2}
      />

      <LogoutModal
        open={open3}
        loading={loading3}
        handleOk={handleOk3}
        handleCancel={handleCancel3}
      />
    </header>
  );
}
