import apiService from "./api";

const baseurl = process.env.REACT_APP_BASE_URL;
const client = "https://backend.vegeel.com/client";

const getAllBusinessesService = async () => {
  return apiService(
    "/vegeel/business",
    "get",
    null,
    true,
    {},
    undefined,
    baseurl
  );
};

const getBusinessesStatsService = async () => {
  return apiService(
    "/vegeel/business/statistics",
    "get",
    null,
    true,
    {},
    undefined,
    baseurl
  );
};
const refreshFrameworkService = async () => {
  return apiService(
    "vegeel/business/reload-framework",
    "get",
    null,
    true,
    {},
    undefined,
    baseurl
  );
};
const deleteBusinessService = async (id: any) => {
  return apiService(
    `/vegeel/business/${id}`,
    "delete",
    null,
    true,
    {},
    undefined,
    baseurl
  );
};
const updateBusinessService = async (id: any, body: any) => {
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  return apiService(
    `/vegeel/business/${id}`,
    "put",
    body,
    true,
    headers,
    undefined,
    baseurl
  );
};
const getBusinessByIdService = async (id: any) => {
  return apiService(
    `/vegeel/business/${id}`,
    "get",
    null,
    true,
    {},
    undefined,
    baseurl
  );
};

const getRolesService = async () => {
  return apiService(`/roles`, "get", null, true, {}, undefined, baseurl);
};

const inviteNewAdminIntoABusinessService = async (body: any, id: string) => {
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  return apiService(
    `/vegeel/business/${id}/invite-new-admin`,
    "post",
    body,
    true,
    headers,
    undefined,
    baseurl
  );
};

const createBusinessService = async (body: any) => {
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  return apiService(
    "/vegeel/business/create",
    "post",
    body,
    true,
    headers,
    undefined,
    baseurl
  );
};

export {
  getAllBusinessesService,
  getBusinessesStatsService,
  refreshFrameworkService,
  deleteBusinessService,
  createBusinessService,
  getBusinessByIdService,
  updateBusinessService,
  getRolesService,
  inviteNewAdminIntoABusinessService,
};
