import { useState, useMemo, useCallback } from "react";
import { ReactComponent as IconDropdown } from "assets/icons/IconDropdown.svg";
import { Dropdown, Space } from "antd";
import { FaSearch, FaTimes } from "react-icons/fa";
import { GoFilter } from "react-icons/go";
import CustomIcon from "components/custom-icon";
import empty from "assets/images/Empty State1.svg";
import {
  ClearButton,
  DropdownWrapper,
  FilterTag,
} from "components/blog/helpers";
import {
  PublishedBlogInput,
  useCategory,
  useDateAdded,
} from "helpers/blog/published";
import BlogCard from "components/blog/card";
import { useBlog } from "context/blog";
import Loader from "components/loader";
interface IContent {
  createdDate: string;
  publicId: string;
  createdBy: string;
  title: string;
  content: string;
  author: string;
  category: string[];
  subTopics: string[];
  image: string;
  visibility: string;
  published: boolean;
  dateAdded: string;
}

interface IDataContent {
  content: IContent[];
}

export default function PublishedBlog() {
  const [nameFilter, setNameFilter] = useState<string>("");
  const [rowsPerPage] = useState<number>(10);
  const { blogData, loadingBlogData } = useBlog();

  const { Category, handleAddCategory, handleRemoveCategory, clearCategory } =
    useCategory();
  const {
    DateAdded,
    handleAddDateAdded,
    handleRemoveDateAdded,
    clearDateAdded,
  } = useDateAdded();

  // Clear all filters
  const handleClearFilters = useCallback(() => {
    clearDateAdded();
    clearCategory();
    setNameFilter("");
  }, [clearDateAdded, clearCategory]);

  // Filter logic
  const filteredData = useMemo(() => {
    if (!Category.length && !DateAdded.length && !nameFilter)
      return blogData.content;

    return blogData.content.filter((item) => {
      const categoryMatch =
        !Category.length || Category.some((cat) => item.category.includes(cat));
      const dateAddedMatch =
        !DateAdded.length || DateAdded.includes(item.createdDate);
      const nameMatch =
        !nameFilter ||
        item.title.toLowerCase().includes(nameFilter.toLowerCase());

      return categoryMatch && dateAddedMatch && nameMatch;
    });
  }, [Category, DateAdded, nameFilter, blogData.content]);

  // Dropdown options for filtering
  const dropdownItems = useMemo(
    () => [
      {
        label: "Category",
        items: Array.from(
          new Set(blogData.content.flatMap((item) => item.category))
        ).map((category) => ({
          label: (
            <div
              onClick={() => handleAddCategory(category)}
              className="flex items-center gap-3 capitalize"
            >
              {category}
            </div>
          ),
          key: category,
        })),
      },
      {
        label: "Date added",
        items: Array.from(
          new Set(blogData.content.map((item) => item.createdDate))
        ).map((date) => ({
          label: (
            <button
              className="capitalize"
              onClick={() => handleAddDateAdded(date)}
            >
              {date}
            </button>
          ),
          key: date,
        })),
      },
    ],
    [handleAddDateAdded, handleAddCategory, blogData.content]
  );

  // Render selected filter tags (category and date)
  const renderFilterTags = () => (
    <>
      {Category.map((category, index) => (
        <FilterTag
          key={`category-${index}`}
          label={category}
          onRemove={() => handleRemoveCategory(category)}
        />
      ))}
      {DateAdded.map((date, index) => (
        <FilterTag
          key={`date-${index}`}
          label={date}
          onRemove={() => handleRemoveDateAdded(date)}
        />
      ))}
    </>
  );
  if (loadingBlogData) return <Loader />;

  return (
    <div className="h-full">
      <div className="flex gap-6 items-center justify-between pb-4">
        <div className="w-2/5">
          <PublishedBlogInput
            placeholder="Search by title..."
            type="text"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
            icon={<FaSearch />}
          />
        </div>
        <div className="flex gap-2.5 items-center">
          <div className="flex gap-1.25 items-center text-gray-700">
            <GoFilter />
            <h1>Filter by</h1>
          </div>
          {dropdownItems.map((dropdown, index) => (
            <DropdownWrapper key={index} dropdown={dropdown} />
          ))}
        </div>
      </div>

      <div className="flex gap-2.5 pb-3.5 items-center">
        {renderFilterTags()}
        {(Category.length > 0 || DateAdded.length > 0 || nameFilter) && (
          <ClearButton onClick={handleClearFilters} />
        )}
      </div>

      {filteredData.length > 0 ? (
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <BlogCard data={filteredData || []} />
        </div>
      ) : (
        <div className="text-center py-6 text-[#8C8C8C] grid items-center justify-center">
          <div>
            <img className="w-full h-full" src={empty} alt="" />
          </div>

          <p className="font-medium  text-[#111111]">No blog published yet</p>
          {/* <p className="text-[#455468] text-sm">{span}</p> */}
        </div>
      )}
    </div>
  );
}
