import QuillToolbar, { modules, formats } from "helpers/quill-tool-bar";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface BlogEditorProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

const BlogEditor = ({ value, setValue }: BlogEditorProps) => {
  const handleChange = (content: string) => {
    setValue(content); // Store HTML content in the editor
  };

  // Function to extract plain text from HTML if needed

  return (
    <div className="w-full h-full">
      <h1 className="font-[400] text-[14px] leading-[24px] text-[#455468] flex gap-1">
        Content
        <span className={`text-[#042BB2] `}>*</span>
      </h1>
      <QuillToolbar toolbarId={"t1"} />
      <div className="h-full overflow-hidden border-[2px]">
        <ReactQuill
          className="bg-white outline-none h-full border-none border-[#D7DFE9]"
          theme="snow"
          value={value} // Keep HTML value for the editor
          readOnly={false}
          onChange={handleChange} // Update value on text change
          modules={modules("t1")}
          formats={formats}
          placeholder="Type in blog comments here.."
        />
      </div>
    </div>
  );
};

export default BlogEditor;
