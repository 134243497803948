import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import { Suspense, lazy, useEffect, useState } from "react";
import Loader from "components/loader";
import vegeelLogo from "assets/images/vegeel-logo.svg";
import overlay from "assets/images/anthenticated_overlay.svg";
import Authenticated from "layouts/authenticated";
import Unauthenticated from "layouts/unauthenticated";
import SettingsNotifications from "modules/authenticted/settings/notification";
import Security from "modules/authenticted/settings/security";
import Permissions from "modules/authenticted/settings/permissions";
import { SettingsProvider } from "context/settings";
import PublishedBlog from "modules/authenticted/blog/published";
import BlogDraft from "modules/authenticted/blog/draft";
import BlogNav from "modules/authenticted/blog/blog-nav";
import Blog from "modules/authenticted/blog";
import AddBlog from "modules/authenticted/blog/add-blog";
import EditBlog from "modules/authenticted/blog/edit-blog";
import PreviewBlog from "modules/authenticted/blog/preview";
import SingleApproval from "modules/authenticted/approvals/single-approval";
import ProtectedRoute from "components/protectedRoutes";
import NotFound from "components/not-found";

// onboarding imports

// Modules imports
const Signin = lazy(() => import("modules/authentication/sign-in"));
const ForgottenPassword = lazy(
  () => import("modules/authentication/forgotten-password")
);
const SetPassword = lazy(() => import("modules/authentication/set-password"));
const Confirmation = lazy(() => import("modules/authentication/confirmation"));
const Otp = lazy(() => import("modules/authentication/otp"));

const Home = lazy(() => import("modules/authenticted/home"));
const Business = lazy(() => import("modules/authenticted/business"));
const SingleBusiness = lazy(
  () => import("modules/authenticted/business/single-business")
);
const CreateBusiness = lazy(
  () => import("modules/authenticted/business/create-busniess")
);

const Subscription = lazy(() => import("modules/authenticted/subscription"));
const Analytics = lazy(() => import("modules/authenticted/analytics"));
const Team = lazy(() => import("modules/authenticted/team"));
const Settings = lazy(() => import("modules/authenticted/settings"));
const Approvals = lazy(() => import("modules/authenticted/approvals"));
const BlogPost = lazy(() => import("modules/authenticted/blog"));
const CareerPost = lazy(() => import("modules/authenticted/careerPost"));
const HelpRequests = lazy(() => import("modules/authenticted/helpRequests"));
const Framework = lazy(() => import("modules/authenticted/framework"));

const MainBody = ({ children }: any) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isDesktop ? (
    <main className="h-dvh w-full tracking-[0.2px]  font-dm_sans overflow-y-scroll">
      {children ? children : <Outlet />}
    </main>
  ) : (
    <div className="h-screen flex flex-col gap-4 justify-center items-center bg-[#06249C] px-3 relative">
      <img src={vegeelLogo} alt="" className="w-[124px] z-20" />
      <img
        src={overlay}
        alt=""
        className="absolute top-0 left-0 h-full w-full object-cover rounded-[20px]"
      />
      <p className="text-center text-sm text-white font-medium uppercase z-20">
        The web app is not accessible on mobile devices. Please verify it's
        functionality on a desktop
      </p>
    </div>
  );
};

const ISuspense = ({ children }: any) => {
  return <Suspense fallback={<Loader />}>{children}</Suspense>;
};

const MainRoutes = () => {
  const modules = createBrowserRouter([
    {
      element: <MainBody />,
      children: [
        {
          path: "/",
          element: <Authenticated />,
          children: [
            {
              index: true,
              element: (
                <ISuspense>
                  <Home />
                </ISuspense>
              ),
            },
            {
              path: "businesses",
              children: [
                {
                  index: true,
                  element: (
                    <ISuspense>
                      <Business />
                    </ISuspense>
                  ),
                },
                {
                  path: ":id",
                  element: (
                    <ISuspense>
                      <SingleBusiness />
                    </ISuspense>
                  ),
                },
                {
                  path: "create-business",
                  element: (
                    <ISuspense>
                      <CreateBusiness />
                    </ISuspense>
                  ),
                },
              ],
            },
            {
              path: "/approvals",
              children: [
                {
                  index: true,
                  element: (
                    <ISuspense>
                      <Approvals />
                    </ISuspense>
                  ),
                },
                {
                  path: ":id",
                  element: (
                    <ISuspense>
                      <SingleApproval />
                    </ISuspense>
                  ),
                },
              ],
            },
            {
              path: "/subscription",
              element: (
                <ISuspense>
                  <Subscription />
                </ISuspense>
              ),
            },
            {
              path: "/analytics",
              element: (
                <ISuspense>
                  <Analytics />
                </ISuspense>
              ),
            },
            {
              path: "/team",
              element: (
                <ISuspense>
                  <Team />
                </ISuspense>
              ),
            },
            {
              path: "/frameworks",
              element: (
                <ISuspense>
                  <Framework />
                </ISuspense>
              ),
            },

            {
              path: "/settings",
              element: (
                <ISuspense>
                  <SettingsProvider>
                    <Settings />
                  </SettingsProvider>
                </ISuspense>
              ),
              children: [
                {
                  index: true,
                  element: (
                    <ISuspense>
                      <SettingsNotifications />
                    </ISuspense>
                  ),
                },
                {
                  path: "permissions",
                  element: (
                    <ISuspense>
                      <Permissions />
                    </ISuspense>
                  ),
                },
                {
                  path: "security",
                  element: (
                    <ISuspense>
                      <Security />
                    </ISuspense>
                  ),
                },
              ],
            },
            {
              path: "/blog",
              element: (
                <ProtectedRoute allowedRoles={["SUPER_ADMIN", "ADMIN"]}>
                  <ISuspense>
                    <Blog />
                  </ISuspense>
                </ProtectedRoute>
              ),
              children: [
                {
                  element: (
                    <ISuspense>
                      <BlogNav />
                    </ISuspense>
                  ),
                  children: [
                    {
                      index: true,
                      element: <PublishedBlog />,
                    },
                    {
                      path: "draft",
                      element: (
                        <ISuspense>
                          <BlogDraft />
                        </ISuspense>
                      ),
                    },
                  ],
                },
                {
                  path: "add-blog",
                  element: (
                    <ISuspense>
                      <AddBlog />
                    </ISuspense>
                  ),
                },
                {
                  path: "edit-blog/:id",
                  element: (
                    <ISuspense>
                      <EditBlog />
                    </ISuspense>
                  ),
                },
                {
                  path: "preview-blog",
                  element: (
                    <ISuspense>
                      <PreviewBlog />
                    </ISuspense>
                  ),
                },
              ],
            },
            {
              path: "/career-post",
              element: (
                <ProtectedRoute allowedRoles={["SUPER_ADMIN", "ADMIN"]}>
                  <ISuspense>
                    <CareerPost />
                  </ISuspense>
                </ProtectedRoute>
              ),
            },
            {
              path: "/help-requests",
              element: (
                <ProtectedRoute allowedRoles={["SUPER_ADMIN", "ADMIN"]}>
                  <ISuspense>
                    <HelpRequests />
                  </ISuspense>
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: "/auth",
          element: <Unauthenticated />,
          children: [
            {
              index: true,
              element: (
                <ISuspense>
                  <Signin />
                </ISuspense>
              ),
            },
            {
              path: "forgotten-password",
              element: (
                <ISuspense>
                  <ForgottenPassword />
                </ISuspense>
              ),
            },
            {
              path: "confirmation",
              element: (
                <ISuspense>
                  <Confirmation />
                </ISuspense>
              ),
            },
            {
              path: "set-password/:token",
              element: (
                <ISuspense>
                  <SetPassword />
                </ISuspense>
              ),
            },
            {
              path: "otp",
              element: (
                <ISuspense>
                  <Otp />
                </ISuspense>
              ),
            },
          ],
        },
        {
          path: "*",
          element: (
            <ISuspense>
              <NotFound />
            </ISuspense>
          ),
        },
      ],
    },
  ]);

  return <RouterProvider router={modules} />;
};

export default MainRoutes;
