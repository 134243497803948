import apiService from "./api";

const baseurl = process.env.REACT_APP_BASE_URL;

const loginService = async (body: any) => {
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  return apiService(
    "/auth/login",
    "post",
    body,
    false,
    headers,
    undefined,
    baseurl
  );
};

const refreshTokenService = async (body: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService(
    "/auth/refresh",
    "post",
    body,
    false,
    headers,
    undefined,
    baseurl
  );
};

const updatePasswordService = async (body: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService(
    "/auth/change-password",
    "post",
    body,
    true,
    headers,
    undefined,
    baseurl
  );
};

const resetPasswordService = async (body: any) => {
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };

  return apiService(
    "/auth/reset-password",
    "post",
    body,
    false,
    headers,
    {},
    baseurl
  );
};

const forgetPasswordService = async (body: any) => {
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  return apiService(
    "/auth/forgot-password",
    "post",
    body,
    false,
    headers,
    {},
    baseurl
  );
};

const generateOtpService = async () => {
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  return apiService(
    "/auth/generate-totp",
    "get",
    null,
    false,
    headers,
    {},
    baseurl
  );
};

const verifyOtpService = async (body: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService(
    "/auth/verify-totp",
    "post",
    body,
    false,
    headers,
    {},
    baseurl
  );
};

export {
  loginService,
  resetPasswordService,
  forgetPasswordService,
  refreshTokenService,
  generateOtpService,
  updatePasswordService,
  verifyOtpService,
};
