import { useState, useMemo, useCallback } from "react";
import { FaSearch } from "react-icons/fa";
import { GoFilter } from "react-icons/go";
import {
  ClearButton,
  DropdownWrapper,
  FilterTag,
} from "components/blog/helpers";
import { PublishedBlogInput } from "helpers/blog/published";
import BlogCard from "components/blog/card";
import { useLastUpdated } from "helpers/blog/draft";
import { useBlog } from "context/blog";
import empty from "assets/images/Empty State1.svg";

// Type for blog draft content
interface BlogDraftContent {
  createdDate: string;
  publicId: string;
  createdBy: string;
  title: string;
  content: string;
  author: string;
  category: string[];
  subTopics: string[];
  image: string | null;
  visibility: string;
  published: boolean;
}

export default function BlogDraft() {
  const [nameFilter, setNameFilter] = useState<string>("");
  const { blogDraft, loadingDraftData } = useBlog();

  const {
    LastUpdated,
    handleAddLastUpdated,
    handleRemoveLastUpdated,
    clearLastUpdated,
  } = useLastUpdated();

  // Clear all filters
  const handleClearFilters = useCallback(() => {
    clearLastUpdated();
    setNameFilter("");
  }, [clearLastUpdated]);

  // Filter logic
  const filteredData = useMemo(() => {
    if (!LastUpdated.length && !nameFilter) return blogDraft;

    return blogDraft.filter((item: BlogDraftContent) => {
      const dateAddedMatch =
        !LastUpdated.length || LastUpdated.includes(item.createdDate);
      const nameMatch =
        !nameFilter ||
        item.title.toLowerCase().includes(nameFilter.toLowerCase());

      return dateAddedMatch && nameMatch;
    });
  }, [LastUpdated, nameFilter, blogDraft]);

  // Dropdown options for filtering
  const dropdownItems = useMemo(
    () => [
      {
        label: "Last updated",
        items: blogDraft.map((item: BlogDraftContent) => ({
          label: (
            <button
              className="capitalize"
              onClick={() => handleAddLastUpdated(item.createdDate)}
            >
              {item.createdDate}
            </button>
          ),
          key: item.createdDate,
        })),
      },
    ],
    [handleAddLastUpdated, blogDraft]
  );

  // Render selected filter tags (category and date)
  const renderFilterTags = () => (
    <>
      {LastUpdated.map((date, index) => (
        <FilterTag
          key={`date-${index}`}
          label={date}
          onRemove={() => handleRemoveLastUpdated(date)}
        />
      ))}
    </>
  );

  return (
    <div className="h-full">
      <div className="flex gap-6 items-center justify-between pb-4">
        <div className="w-2/5">
          <PublishedBlogInput
            placeholder="Search by name..."
            type="text"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
            icon={<FaSearch />}
          />
        </div>

        <div className="flex gap-2.5 items-center">
          <div className="flex gap-1.25 items-center text-gray-700">
            <GoFilter />
            <h1>Filter by</h1>
          </div>
          {dropdownItems.map((dropdown, index) => (
            <DropdownWrapper key={index} dropdown={dropdown} />
          ))}
        </div>
      </div>

      <div className="flex gap-2.5 pb-3.5 items-center">
        {renderFilterTags()}
        {(LastUpdated.length > 0 || nameFilter) && (
          <ClearButton onClick={handleClearFilters} />
        )}
      </div>
      {filteredData.length > 0 ? (
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <BlogCard data={filteredData || []} />
        </div>
      ) : (
        <div className="text-center py-6 text-[#8C8C8C] grid items-center justify-center">
          <div>
            <img className="w-full h-full" src={empty} alt="" />
          </div>

          <p className="font-medium  text-[#111111]">No draft yet</p>
          {/* <p className="text-[#455468] text-sm">{span}</p> */}
        </div>
      )}
    </div>
  );
}
