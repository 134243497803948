import Select from "react-select";
import { ReactComponent as CheveronDown } from "assets/icons/chevron-down.svg";
import CustomIcon from "components/custom-icon";
import { toast } from "sonner";

interface OptionType {
  value: string;
  label: string;
}

interface Props {
  placeholder: string;
  options?: any;
  border?: string;
  isMulti?: boolean;
  onChange?: (selectedOptions: any) => void;
  defaultValue?: any;
  error?: any;
  value?: any;
}

const SelectComponent: React.FC<Props> = ({
  options,
  placeholder,
  border,
  isMulti,
  onChange,
  defaultValue,
  error,
  value,
}) => {
  const selectedValue = value || (isMulti ? [] : {});

  return (
    <Select
      isMulti={isMulti}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? "#AFBACA" : "#AFBACA",
          padding: ".2rem",
          border: border,
        }),
        placeholder: (defaultStyles) => ({
          ...defaultStyles,
          color: "#AFBACA",
          fontSize: "14px",
          fontWeight: "400",
        }),
      }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 6,
        colors: {
          ...theme.colors,
          primary: "black",
        },
      })}
      options={options}
      placeholder={placeholder}
      components={{
        DropdownIndicator: () => (
          <div className="px-2">
            <CustomIcon SvgIcon={CheveronDown} size={16} />
          </div>
        ),
      }}
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
    />
  );
};

export default SelectComponent;
