import { ReactComponent as helpIcon } from "../../../../assets/icons/help-circle-contained.svg";
import CustomIcon from "components/custom-icon";
import { getTokenFromLocalStore, saveTokenToLocalStore } from "utils";
import { useNavigate } from "react-router-dom";
import arrowLeft from "../../../../assets/icons/arrow-left.svg";
import { ReactComponent as Upload } from "../../../../assets/icons/upload-white.svg";
import { ReactComponent as logo } from "../../../../assets/icons/vegeelLogo.svg";
import useFetch from "hooks/useFetch";
import { createBlog, updateBlog } from "services/blog";
import { useBlog } from "context/blog";
import { uploadFilesService } from "services/app";
import { useEffect, useState } from "react";
import { toast } from "sonner";

const styles = {
  frameworks: {
    borderColor: "#EA6F03",
    bgColor: "#FFFAEB",
    textColor: "#EA6F03",
  },
  updates: {
    borderColor: "#455468",
    bgColor: "#FAFAFA",
    textColor: "#455468",
  },
  cybersecurity: {
    borderColor: "#027A48",
    bgColor: "#ECFDF3",
    textColor: "#027A48",
  },
  riskAssesment: {
    borderColor: "#1B4DFF",
    bgColor: "#F2F5FF",
    textColor: "#1B4DFF",
  },
  "news and events": {
    borderColor: "#CB1A14",
    bgColor: "#FFF5F4",
    textColor: "#CB1A14",
  },
  default: {
    borderColor: "#000",
    bgColor: "#FFF",
    textColor: "#000",
  },
};

const normalizeCategory = (value: string): keyof typeof styles => {
  const categoryMap: Record<string, keyof typeof styles> = {
    RISK_ASSESSMENT: "riskAssesment",
    CYBERSECURITY: "cybersecurity",
    FRAMEWORK: "frameworks",
    UPDATES: "updates",
    "NEWS AND EVENTS": "news and events",
  };

  return categoryMap[value.toUpperCase()] || "default";
};

const getStyle = (value: string) => {
  const normalizedCategory: keyof typeof styles = normalizeCategory(value);
  return styles[normalizedCategory];
};

const Category: React.FC<{ value: string }> = ({ value }) => {
  const { borderColor, bgColor, textColor } = getStyle(value);

  return (
    <div
      className="flex items-center rounded-[6px] py-[8px] px-[4px] gap-2"
      style={{
        border: `1px solid ${borderColor}`,
        backgroundColor: bgColor,
        color: textColor,
      }}
    >
      <span className="text-[14px] font-[500] capitalize">{value}</span>
    </div>
  );
};

interface IDataResponse {
  isSuccess: boolean;
  isError: boolean;
  description: string;
  status: string;
  data: {
    secure_url: string;
  };
}

export default function PreviewBlog() {
  const previewData: any = getTokenFromLocalStore("blogPrev1ew");
  const fromDraft: any = getTokenFromLocalStore("comingfromDraft");
  const formEdit: any = getTokenFromLocalStore("comingFormEdit");
  const newBlog: any = getTokenFromLocalStore("newBlog");
  const navigate = useNavigate();
  const [imageUrl, setImageurl] = useState<any>("");
  const [disabled, setDisabled] = useState<boolean>(false);
  const { error, loading, fetchData, data } = useFetch<IDataResponse>();
  const { setReload, preview, setPreview, comingFromDraft } = useBlog();
  const date = new Date();
  const formattedDate = formatDate(date);

  const {
    error: updateError,
    loading: loadingUpdate,
    fetchData: runUpdateBlog,
    data: updateResponse,
  } = useFetch<IDataResponse>();

  const {
    error: upLoadImageError,
    loading: uploadImageLoading,
    fetchData: uploadFetch,
    data: uploadData,
  } = useFetch<IDataResponse>();

  function formatDate(date: Date): string {
    return new Intl.DateTimeFormat("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  }

  useEffect(() => {
    if (upLoadImageError) {
      toast.error(upLoadImageError);
      setDisabled(false);
    } else if (error) {
      toast.error(error);
      setDisabled(false);
    } else if (updateError) {
      toast.error(updateError);
      setDisabled(false);
    }
  }, [upLoadImageError, error, updateError]);

  const handleNavigatePrevious = () => {
    if (previewData.publicId || previewData.id) {
      setPreview(false);
      localStorage.removeItem("comingfromDraft");
      localStorage.removeItem("comingFormEdit");
      localStorage.removeItem("newBlog");
      saveTokenToLocalStore("editData", previewData);
      navigate(`/blog/edit-blog/${previewData.publicId}`);
    } else {
      navigate("/blog/add-blog");
    }
  };

  const publishBLog = () => {
    console.log(previewData);
    if (!previewData.image) {
      return null;
    } else if (
      previewData.image.includes("cloudinary") &&
      fromDraft !== "yes"
    ) {
      const upload = { ...previewData, published: true };

      fetchData(() => createBlog(upload));
    } else {
      const parsedData = previewData;
      const base64Image = parsedData.image;

      const byteString = atob(base64Image.split(",")[1]);
      const mimeString = base64Image.split(",")[0].split(":")[1].split(";")[0];

      const byteArray = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        byteArray[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([byteArray], { type: mimeString });
      const file = new File([blob], "image_file.png", { type: mimeString });
      parsedData.image = file;

      uploadFetch(() => uploadFilesService(file));
    }
  };

  useEffect(() => {
    if (data !== null) {
      if (data.isSuccess) {
        toast.success("Upload successful");
        localStorage.removeItem("blogPrev1ew");
        localStorage.removeItem("comingfromDraft");
        localStorage.removeItem("comingFormEdit");
        localStorage.removeItem("newBlog");
        navigate("/blog");
        setReload(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (updateResponse !== null) {
      if (updateResponse.isSuccess) {
        toast.success("Upload successful");
        localStorage.removeItem("blogPrev1ew");
        localStorage.removeItem("comingfromDraft");
        localStorage.removeItem("comingFormEdit");
        localStorage.removeItem("newBlog");
        navigate("/blog");
        setReload(true);
      }
    }
  }, [updateResponse]);

  useEffect(() => {
    if (uploadData !== null) {
      if (!previewData.id && fromDraft !== "yes") {
        const secureUrl = uploadData?.data.secure_url;
        setImageurl(secureUrl);

        if (secureUrl) {
          const upload = { ...previewData, image: secureUrl, publised: true };

          fetchData(() => createBlog(upload));
        }
      } else {
        return;
      }
    }
  }, [uploadData, previewData.id, fromDraft]);

  useEffect(() => {
    if (loading || loadingUpdate || uploadImageLoading) {
      setDisabled(true);
    }
  }, [loading, loadingUpdate, uploadImageLoading]);

  const savechanges = () => {
    if (previewData.image.includes("cloudinary")) {
      const id =
        previewData.id === undefined ? previewData.publicId : previewData.id;
      const upload = { ...previewData, published: true };
      runUpdateBlog(() => updateBlog(id, upload));
    } else {
      if (!previewData.image) return null;

      const parsedData = previewData;
      const base64Image = parsedData.image;

      const byteString = atob(base64Image.split(",")[1]);
      const mimeString = base64Image.split(",")[0].split(":")[1].split(";")[0];

      const byteArray = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        byteArray[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([byteArray], { type: mimeString });
      const file = new File([blob], "image_file.png", { type: mimeString });
      parsedData.image = file;

      uploadFetch(() => uploadFilesService(file));
    }
  };

  useEffect(() => {
    if (uploadData !== null) {
      const id =
        previewData.id === undefined ? previewData.publicId : previewData.id;
      console.log(id);
      if (
        (previewData.id && fromDraft === "no") ||
        (previewData.id && fromDraft === null)
      ) {
        const secureUrl = uploadData?.data.secure_url;
        setImageurl(secureUrl);

        if (secureUrl) {
          const upload = { ...previewData, image: secureUrl, published: true };
          // console.log("boom");
          runUpdateBlog(() => updateBlog(id, upload));
        }
      } else if (previewData.id && fromDraft === "yes") {
        const secureUrl = uploadData?.data.secure_url;
        setImageurl(secureUrl);

        if (secureUrl) {
          const upload = { ...previewData, image: secureUrl, published: true };
          // console.log("bamm");
          runUpdateBlog(() => updateBlog(id, upload));
        }
      } else {
        return;
      }
    }
  }, [uploadData]);

  return (
    <div className="px-10 py-[24px] overflow-auto h-full flex flex-col gap-[2rem] relative">
      <div className="w-full flex justify-between items-center  ">
        <div className="flex items-center gap-3 bg-[#F2F5FF] text-[#1B4DFF] py-[6px] px-[12px] rounded-[6px] text-[14px]">
          Preview Blog <CustomIcon SvgIcon={helpIcon} size={16} />
        </div>

        <div className="flex gap-4 items-center">
          <button
            onClick={handleNavigatePrevious}
            className="py-[8px] px-[15px] bg-[white] rounded-[6px] flex justify-center items-center gap-[10px] border"
          >
            <img src={arrowLeft} alt="" />
            <h1 className="font-[500] text-[14px] leading-[24px] text-[#455468]">
              Edit
            </h1>
          </button>
          {fromDraft === "yes" || formEdit === "yes" || newBlog === "yes" ? (
            <button
              disabled={disabled}
              onClick={
                previewData.id
                  ? savechanges
                  : previewData.publicId && fromDraft === "yes"
                  ? savechanges
                  : publishBLog
              }
              className={`py-[8px] px-[15px] bg-[#1B4DFF] rounded-[6px] flex justify-center items-center gap-[10px] text-white ${
                disabled ? "opacity-20 cursor-not-allowed" : ""
              }`}
            >
              <CustomIcon SvgIcon={Upload} size={18} />

              <h1 className="font-[500] text-[14px] leading-[24px]">
                {previewData.id && previewData.published
                  ? "Save Changes "
                  : previewData.publicId &&
                    fromDraft === "yes" &&
                    !previewData.published
                  ? "Upload to blog"
                  : "Publish blog"}
              </h1>
            </button>
          ) : (
            <button
              onClick={() => navigate("/blog")}
              className="py-[8px] px-[15px] bg-[#1B4DFF] rounded-[6px] flex justify-center items-center gap-[10px] border"
            >
              <h1 className="font-[500] text-[14px] leading-[24px] text-[white]">
                Back to blog
              </h1>
            </button>
          )}
        </div>
      </div>

      <div className="text-[60px] font-[700] text-[#111111] capitalize">
        {previewData.title}
      </div>

      <div className="flex items-center gap-3 ">
        <CustomIcon SvgIcon={logo} size={36} />
        <span className="text-[18px] font-[500] text-[#111111]">by Vegeel</span>
        <span className="bg-[#455468] w-[5px] h-[5px] rounded-[20px]"></span>
        <span>{formattedDate}</span>
        <span className="bg-[#455468] w-[5px] h-[5px] rounded-[20px]"></span>
        {previewData.category.map((category: string) => (
          <Category key={category} value={category} />
        ))}
      </div>

      <div className="w-full">
        <img
          src={previewData.image}
          alt=""
          className="w-full h-[600px] object-cover"
        />
      </div>

      <div>
        <div className="text-[#111111] text-[20px] font-[700] ">Content</div>
        <div className="flex items-baseline">
          <div className="w-[20%]">
            {previewData.subTopics.map((topic: string, index: number) => (
              <div
                key={index}
                className={`w-full border-l-4 border-[#1B4DFF] h-[56px] pl-3 pt-2 capitalize ${
                  index === 0
                    ? "border-l-3 border-[#1B4DFF]"
                    : "border-[#E5EAFC] border-l-3"
                }`}
              >
                {topic}
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-[2rem] w-[70%]">
            <div className="text-[36px] font-[700] text-[#111111] capitalize">
              {previewData.subTopics[0]}
            </div>

            <div className="text-[1rem] text-[400] leading-[28px]">
              {previewData.content}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
