import React, { useState } from "react";
import arrowDown from "../../../assets/icons/arrow-down-black.svg";
import SettingButton from "../button";
import { Space, Switch } from "antd";

interface rolesProps {
  data: any;
}

export default function Roles({ data }: rolesProps) {
  const [ShowDropDown, setShowDropDown] = useState<boolean>(false);
  const [accessPermissions, setAccessPermissions] = useState<boolean>(false);
  const [reportsChange, setReportsChange] = useState<boolean>(false);

  const handleAccesshChange = (checked: boolean) => {
    setAccessPermissions(checked);
  };

  const reportsChanged = (checked: boolean) => {
    setReportsChange(checked);
  };

  return (
    <div className="w-full h-fit py-6 bg-[#FFFFFF] border-[#D7DFE9] border-[1px] rounded-[6px]">
      <div className="flex items-center h-full px-4 justify-between ">
        <div className="flex items-center gap-5 w-[60%]">
          <div className="capitalize text-[1rem] text-[#111111] font-[500]">
            {data.name}
          </div>
          <div className="bg-[#F2F5FF] px-[8px] py-[2px] text-[#1B4DFF] text-[14px] font-[500] rounded-[6px]">
            Default
          </div>
        </div>

        <div className="flex items-center justify-between w-[40%]">
          <div className="px-[8px] py-[2px] bg-[#EAECF0] rounded-[6px] text-[#455468] text-[14px] font-[400] capitalize">
            {data.permissions.length} permissions
          </div>
          <div
            onClick={() => setShowDropDown(!ShowDropDown)}
            className={`cursor-pointer ${
              !ShowDropDown
                ? "rotate-0 duration-300"
                : "-rotate-180 duration-300  "
            }`}
          >
            <img src={arrowDown} alt="Arrow icon pointing down" />
          </div>
        </div>
      </div>

      {ShowDropDown && (
        <div className="h-fit flex p-6 flex-col w-full duration-300">
          {/* <div className="text-[#455468] text-[14px] text-[400] w-[60%]">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam
            sequi delectus perferendis, quaerat rem ducimus. Laudantium laborum
            aliquam quisquam voluptas sed ipsum molestiae, voluptates dolorum
            earum ducimus fugiat nemo corporis.
          </div> */}

          <div className="py-3 border-b h-[54px] items-center border-[#D7DFE9] flex justify-between">
            <div className="w-[42%]">
              <h1 className="">Apps</h1>
            </div>
            <div className="w-[48%]">
              <h1 className="">Permissions</h1>
            </div>
            <div className="w-fit">
              <SettingButton
                variant="danger-outline"
                text="Restore Default"
                className="p-[12px]"
              />
            </div>
          </div>
          {/* permissions */}
          <div className="border-b border-[#D7DFE9] py-3 capitalize flex px-3">
            <div className="capitalize text-[1rem] text-[#111111] font-[400] w-[30%]">
              1. dashboard
            </div>

            <div className="w-[30%]">
              <Space>
                <Switch defaultChecked onChange={handleAccesshChange} />
                <h1 className="font-[400]">Access</h1>
              </Space>
            </div>

            <div className="flex flex-col gap-3 w-[25%] ">
              <div className="flex items-center justify-between">
                <div className="capitalize text-[1rem] text-[#111111] font-[400] ">
                  Reports for testing
                </div>

                <Space>
                  <Switch defaultChecked onChange={reportsChanged} />
                  <p className="capitalize text-[1rem] text-[#111111] font-[400]">
                    yes
                  </p>
                </Space>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
