import React, { ChangeEvent } from "react";

interface InputProps {
  icon?: React.ReactNode;
  label: string;
  placeholder: string;
  type: "text" | "password" | "email" | "textarea";
  value?: string;
  name?: string;
  required: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const BlogInput: React.FC<InputProps> = ({
  icon,
  placeholder,
  type,
  value,
  label,
  required,
  name,
  onChange,
}) => {
  return (
    <div>
      <h1 className="font-[400] text-[14px] leading-[24px] text-[#455468] flex gap-1">
        {label}
        <span className={`text-[#042BB2] ${required ? "block" : "hidden"}`}>
          *
        </span>
      </h1>
      <div
        className={`flex rounded-md items-center p-[10px] border border-[#AFBACA]`}
      >
        {icon && <span className={`border-r px-[10px]`}>{icon}</span>}
        {type === "textarea" ? (
          <textarea
            placeholder={placeholder}
            value={value}
            name={name}
            required={required}
            onChange={onChange}
            className={`w-full text-sm ${
              icon ? "ml-[10px]" : ""
            } focus:outline-none resize-none`}
            rows={3}
          />
        ) : (
          <input
            type={type}
            placeholder={placeholder}
            value={value}
            name={name}
            required={required}
            onChange={onChange}
            className={`w-full ${
              icon ? "ml-[10px]" : ""
            } focus:outline-none text-sm`}
          />
        )}
      </div>
    </div>
  );
};

export default BlogInput;
