import React from "react";
import CustomIcon from "components/custom-icon";
import { Link, useLocation } from "react-router-dom";
import { SidebarTopProps } from "types";

export default function Navlink({ icon, link, title }: SidebarTopProps) {
  const { pathname } = useLocation();
  const blogpage = link.includes("blog");

  return (
    <Link
      to={link}
      className={`w-full h-[32px] flex items-center gap-[10px] ${
        pathname === link ? "active-sidebar" : ""
      } px-2 rounded-md hover:bg-gradient-to-r-blue`}
    >
      <CustomIcon SvgIcon={icon} size={18} />
      <span className="text-[#FFFFFF] text-[14px] leading-[24px]">{title}</span>
    </Link>
  );
}
