import { Switch, TimePicker, Space, Modal, Button } from "antd";
import React, { useEffect, useState } from "react";
import type { Dayjs } from "dayjs";
import { useAppContext } from "context/home/app";
import { useSettings } from "context/settings";
import { toast } from "sonner";
import useFetch from "hooks/useFetch";
import {
  disableMultifactorauthentication,
  enableMultifactorauthentication,
} from "services/settings";
import { getUserByIdService } from "services/app";
import { formatTime } from "utils/general";
import dayjs from "dayjs";

export default function Security() {
  const { getUserInfo } = useAppContext();
  const userInfo = getUserInfo();
  const { setSecurityRequestBody } = useSettings();
  const { setUserInfo } = useAppContext();

  const {
    data: enableMFAData,
    error: enableMFAError,
    loading: enableMFALoading,
    fetchData: fetchDataEnableMFA,
  } = useFetch<any>();

  const {
    data: disableMFAData,
    error: disableMFAError,
    loading: disableMFALoading,
    fetchData: fetchDataDisableMFA,
  } = useFetch<any>();

  const {
    data: getUserInfoByIdData,
    error: getUserInfoByIdError,
    loading: getUserInfoByIdLoading,
    fetchData: fetchDataGetUserInfoById,
  } = useFetch<any>();

  // Extracted user data
  const isFaEnabled: any = userInfo.is2faEnabled;
  const sessiontimeOut = userInfo?.sessionTimeout;
  const automaticLogOut = userInfo?.automaticLogoutTime;

  // States
  const [requireMultifactorAuth, setRequireMultifactorAuth] =
    useState<boolean>(false);

  const [idleSessiontimeOut, setIdleSessiontimeOut] = useState<Dayjs | null>(
    sessiontimeOut ? dayjs(sessiontimeOut, "HH:mm:ss") : null
  );
  const [autoLogOutValue, setAutoLogOutValue] = useState<Dayjs | null>(
    automaticLogOut ? dayjs(automaticLogOut, "HH:mm:ss") : null
  );
  const [qrCodeData, setQrCodeData] = useState<string | null>(null);
  const [setupKeyData, setSetupKeyData] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [body, setBody] = useState({
    idleSessiontimeOut: sessiontimeOut,
    autoLogOut: automaticLogOut,
  });

  const handleMFAChange = async (checked: boolean): Promise<void> => {
    setRequireMultifactorAuth(checked);

    if (checked) {
      fetchDataEnableMFA(() => enableMultifactorauthentication());
    } else {
      fetchDataDisableMFA(() => disableMultifactorauthentication());
    }
  };

  useEffect(() => {
    if (enableMFAData) {
      setIsModalVisible(true);
      setQrCodeData(enableMFAData.data.qrCodeUrl);
      setSetupKeyData(enableMFAData.data.secret);
      getUpdatedUserProfile();
      toast.success("MFA enabled successfully!");
    }
  }, [enableMFAData]);

  const getUpdatedUserProfile = () => {
    fetchDataGetUserInfoById(() => getUserByIdService(userInfo?.publicId));
  };

  useEffect(() => {
    if (getUserInfoByIdData) {
      setUserInfo(getUserInfoByIdData?.data);
    }
  }, [getUserInfoByIdData]);

  useEffect(() => {
    if (disableMFAData) {
      getUpdatedUserProfile();
      toast.success("MFA disabled successfully!");
    }
  }, [disableMFAData]);

  useEffect(() => {
    if (enableMFAError || disableMFAError) {
      toast.error(enableMFAError || disableMFAError);
    }
  }, [enableMFAError, disableMFAError]);

  const handleAutoLogOutChange = (time: Dayjs | null) => {
    if (time && dayjs.isDayjs(time)) {
      const formattedTime = formatTime(time);
      setAutoLogOutValue(time);
      setBody({
        ...userInfo,
        autoLogOut: formattedTime,
      });
    } else {
      console.error("Invalid time value:", time);
    }
  };

  const handleIdleSessionTimeoutChange = (time: Dayjs | null) => {
    if (time && dayjs.isDayjs(time)) {
      const formattedTime = formatTime(time);
      setIdleSessiontimeOut(time);
      setBody({
        ...userInfo,
        idleSessiontimeOut: formattedTime,
      });
    } else {
      console.error("Invalid time value:", time);
    }
  };

  useEffect(() => {
    setSecurityRequestBody(body);
  }, [requireMultifactorAuth, idleSessiontimeOut, autoLogOutValue]);

  const format = "HH:mm";

  const formattedSessionTimeout = sessiontimeOut
    ? dayjs(sessiontimeOut, "HH:mm:ss").format("HH:mm")
    : null;

  const formattedutoLogOutValue = autoLogOutValue
    ? dayjs(autoLogOutValue, "HH:mm:ss").format("HH:mm")
    : null;

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <div className="flex flex-col">
        {/* Multi-factor Authentication */}
        <section className="flex gap-[60px] items-center border-b pb-[16px]">
          <div className="w-[25%]">
            <h1 className="font-[500] text-[14px] leading-[24px]">
              Multi-factor authentication
            </h1>
            <h1 className="text-[#455468] text-[14px] leading-[24px] font-[400]">
              This makes your account extra secure. Along with your password,
              you'll need to enter the secret code that we sent to your email
              each time you log in.
            </h1>
          </div>
          <div>
            <Space>
              <Switch
                onChange={handleMFAChange}
                loading={enableMFALoading || disableMFALoading} // Loading state
                {...(isFaEnabled
                  ? { defaultChecked: true } // When isFaEnabled is true, add defaultChecked
                  : { checked: !!requireMultifactorAuth })} // Otherwise, control with checked state
              />
              Require code at login
            </Space>
          </div>
        </section>

        {/* Idle Session Timeout */}
        <section className="flex gap-[60px] items-center border-b pb-[16px] py-[24px]">
          <div className="w-[25%]">
            <h1 className="font-[500] text-[14px] leading-[24px]">
              Idle session timeout
            </h1>
            <h1 className="text-[#455468] text-[14px] leading-[24px] font-[400]">
              This will automatically log out your account when your account is
              idle within a particular time.
            </h1>
          </div>
          <div className="w-[150px]">
            <TimePicker
              value={idleSessiontimeOut}
              onChange={handleIdleSessionTimeoutChange}
              defaultValue={
                formattedSessionTimeout
                  ? dayjs(formattedSessionTimeout, format)
                  : null
              }
              placeholder="Set time"
              size="large"
              format={format}
            />
          </div>
        </section>

        {/* Automatic Logout */}
        <section className="flex gap-[60px] items-center border-b pb-[16px] py-[24px]">
          <div className="w-[25%]">
            <h1 className="font-[500] text-[14px] leading-[24px]">
              Automatic logout
            </h1>
            <h1 className="text-[#455468] text-[14px] leading-[24px] font-[400]">
              Set your account to automatically logout at a particular period of
              time. When set, your account will be logged out always at that
              time.
            </h1>
          </div>
          <div>
            <TimePicker
              value={autoLogOutValue}
              onChange={handleAutoLogOutChange}
              defaultValue={
                formattedutoLogOutValue
                  ? dayjs(formattedutoLogOutValue, format)
                  : null
              }
              placeholder="Set time"
              size="large"
              format="HH:mm"
            />
          </div>
        </section>
      </div>

      {/* Modal to display QR Code */}
      <Modal
        title={
          <h2 className="text-[18px] font-semibold">
            Scan the QR code using your authenticator app:
          </h2>
        }
        open={isModalVisible}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={[
          <Button key="close" type="primary" onClick={handleModalClose}>
            Close
          </Button>,
        ]}
      >
        {qrCodeData && (
          <div className="text-center">
            <p className="mb-[16px] text-[16px] leading-[24px]"></p>
            <img
              src={qrCodeData}
              alt="QR Code for 2FA setup"
              style={{
                width: "300px",
                height: "300px",
                margin: "0 auto 16px",
                border: "2px solid #e0e0e0",
                borderRadius: "8px",
              }}
            />
            <h3 className="text-[16px] font-medium mt-[12px]">
              Or enter this setup key:
            </h3>
            <div
              className="text-[16px] font-mono p-[8px] bg-[#f7f7f7] rounded-[8px] inline-block mt-[8px] break-words"
              style={{
                wordWrap: "break-word",
                wordBreak: "break-all",
                whiteSpace: "normal",
                maxWidth: "100%",
              }}
            >
              {setupKeyData}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}
