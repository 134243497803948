import React from "react";
import { Select, Space } from "antd";
import type { SelectProps } from "antd";
import CustomIcon from "components/custom-icon";
import { ReactComponent as dropdownIcon } from "../../../assets/icons/arrow-down-black.svg";
import CloseIcon from "../xIcon";

const getStyle = (value: string) => {
  switch (value) {
    case "FRAMEWORK":
      return {
        borderColor: "#EA6F03",
        bgColor: "#FFFAEB",
        textColor: "#EA6F03",
      };
    case "updates":
      return {
        borderColor: "#455468",
        bgColor: "#FAFAFA",
        textColor: "#455468",
      };
    case "CYBERSECURITY":
      return {
        borderColor: "#027A48",
        bgColor: "#ECFDF3",
        textColor: "#027A48",
      };
    case "RISK_ASSESSMENT":
      return {
        borderColor: "#1B4DFF",
        bgColor: "#F2F5FF",
        textColor: "#1B4DFF",
      };
    case "news and events":
      return {
        borderColor: "#CB1A14",
        bgColor: "#FFF5F4",
        textColor: "#CB1A14",
      };
    default:
      return { borderColor: "#000", bgColor: "#FFF", textColor: "#000" };
  }
};

// Component for rendering selected categories
const SelectedCategory: React.FC<{ value: string; cancel: () => void }> = ({
  value,
  cancel,
}) => {
  const { borderColor, bgColor, textColor } = getStyle(value);

  return (
    <div
      className="flex items-center rounded-[6px] py-[8px] px-[4px] gap-2"
      style={{
        border: `1px solid ${borderColor}`,
        backgroundColor: bgColor,
        color: textColor,
      }}
    >
      <span className="text-[14px] font-[500] capitalize">{value}</span>
      <div
        className={`cursor-pointer shadow-sm rounded-[10px] border border-[${borderColor}]`}
        onClick={cancel}
      >
        <CloseIcon color={borderColor} size={20} />
      </div>
    </div>
  );
};

// Selector Component with props
interface SelectorProps {
  selectedOptions: string[];
  setSelectedOptions: (options: string[]) => void;
}

const Selector: React.FC<SelectorProps> = ({
  selectedOptions,
  setSelectedOptions,
}) => {
  const [value, setValue] = React.useState<string>("");

  const handleChange = (value: string) => {
    setValue(value);
    setSelectedOptions([...selectedOptions, value]);
  };

  const removeSubtopic = (index: number) => {
    setSelectedOptions(selectedOptions.filter((_, i) => i !== index));
    setValue("");
  };

  const options: SelectProps["options"] = [
    { label: "Frameworks", value: "FRAMEWORK" },
    // { label: "Updates", value: "updates" },
    { label: "Cybersecurity", value: "CYBERSECURITY" },
    // { label: "News & Events", value: "news and events" },
    { label: "Risk Assesment", value: "RISK_ASSESSMENT" },
  ];

  return (
    <div className="w-full">
      <h1 className="font-[400] text-[14px] leading-[24px] text-[#455468] flex gap-1">
        Category tag <span className="text-[#042BB2]">*</span>
      </h1>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Select
          value={value}
          placeholder="Framework, Risk Assessment, Cybersecurity...."
          onChange={handleChange}
          style={{ width: "100%" }}
          options={options}
          suffixIcon={<CustomIcon SvgIcon={dropdownIcon} size={16} />}
        />
      </Space>
      <div className="flex flex-wrap gap-3 py-2">
        {selectedOptions?.map((option, index) => (
          <SelectedCategory
            key={index}
            value={option}
            cancel={() => removeSubtopic(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Selector;
