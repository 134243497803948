import React, { InputHTMLAttributes } from "react";
interface ButtonProps extends InputHTMLAttributes<HTMLButtonElement> {
  text?: string;
  icon?: any;
  variant: "contained" | "outline" | "plain" | "danger" | "danger-outline";
  onClick?: any;
  className?: string;
}

export default function SettingButton({
  icon,
  text,
  variant,
  onClick,
  className,
}: ButtonProps) {
  const getButtonVariant = () => {
    switch (variant) {
      case "contained":
        return "bg-[#1B4DFF] text-white px-[15px] py-[8px]";
      case "danger":
        return "bg-[#E92215] text-white";
      case "danger-outline":
        return "border border-[#FFDCDA] text-[#E92215]";
      case "outline":
        return "border border-[#D7DFE9] px-[15px] py-[8px] bg-white text-[#455468]";
      case "plain":
        return "border-[1px] border-[#D7DFE9] bg-[#ffff]";

      default:
        return "";
    }
  };

  return (
    <button
      onClick={onClick}
      className={`w-full h-[40px] flex justify-center shadow-lg shadow-[#A2B5DA26] whitespace-nowrap items-center gap-3 rounded-md text-sm leading-[24px]  capitalize font-medium ${getButtonVariant()} ${className}`}
    >
      {icon && <span>{icon}</span>}
      {text && <h1 className="text-center">{text}</h1>}
    </button>
  );
}
