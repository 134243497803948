import { Dropdown, Space } from "antd";
import CustomIcon from "components/custom-icon";
import { ReactComponent as IconDropdown } from "assets/icons/IconDropdown.svg";
import { FaTimes } from "react-icons/fa";

export const FilterTag = ({ label, onRemove }: any) => (
  <div className="border border-gray-300 p-1 text-gray-700 rounded-md text-sm capitalize cursor-pointer">
    <Space>
      {label}
      <FaTimes className="text-sm" onClick={onRemove} />
    </Space>
  </div>
);

export const DropdownWrapper = ({ dropdown }: any) => (
  <div className="cursor-pointer">
    <Dropdown
      menu={{ items: dropdown.items }}
      trigger={["click"]}
      className="py-2 px-4 rounded-md bg-white shadow-md"
    >
      <div className="text-sm text-gray-700 font-medium leading-6 capitalize">
        <Space>
          {dropdown.label}
          <CustomIcon SvgIcon={IconDropdown} size={12} />
        </Space>
      </div>
    </Dropdown>
  </div>
);

export const ClearButton = ({ onClick }: any) => (
  <div className="cursor-pointer capitalize" onClick={onClick}>
    <Space>
      <h1 className="underline">Clear</h1>
      <FaTimes className="text-sm" />
    </Space>
  </div>
);
