import { useHeaderContext } from "hooks/useHeader";
import { useEffect, useState } from "react";
import arrowLeft from "../../../../assets/icons/arrow-left.svg";
import BlogEditor from "components/blog/ediitor";
import BlogInput from "components/blog/input";
import BlogImageUploader from "components/blog/uploader";
import Selector from "components/blog/selector";
import CustomIcon from "components/custom-icon";
import { ReactComponent as plusIncon } from "../../../../assets/icons/plus-icon-blue.svg";
import { ReactComponent as deleteIcon } from "../../../../assets/icons/x-small.svg";
import { useNavigate } from "react-router-dom";
import { useBlog } from "context/blog";
import { getTokenFromLocalStore, saveTokenToLocalStore } from "utils";
import useFetch from "hooks/useFetch";
import { createBlog, UploadBlogUrl } from "services/blog";
import { uploadFilesService } from "services/app";
import { toast } from "sonner";

interface IDataResponse {
  isSuccess: boolean;
  isError: boolean;
  description: string;
  status: string;
  data: {
    secure_url: string;
  };
}

interface SubtopicOptions {
  value: string;
  index: number;
  cancel: () => void;
}

export function SubTopic({ value, index, cancel }: SubtopicOptions) {
  return (
    <div className="flex w-full justify-between items-center py-2">
      <div className="flex items-center gap-2 text-[14px] font-[400] text-[#111111]  w-[80%] justify-start truncate overflow-hidden whitespace-nowrap">
        <span>{index + 1}.</span>
        <span className="truncate">{value}</span>
      </div>
      <div
        onClick={cancel}
        className="underline flex items-center gap-2 text-[14px] font-[400] text-[#455468] cursor-pointer"
      >
        Clear
        <CustomIcon SvgIcon={deleteIcon} size={20} />
      </div>
    </div>
  );
}

export default function AddBlog() {
  const { setHeaderTitle } = useHeaderContext();
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [subtopics, setSubtopics] = useState<string>("");
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [subBlogTopics, setSubBlogTopics] = useState<string[]>([]);
  const [disabledBtn, setDisabledBtn] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [imageData, setImageData] = useState<any | null>(null);
  const [sendimage, setSendimage] = useState<any>();
  const { error, loading, fetchData, data } = useFetch<IDataResponse>();
  const { setReload } = useBlog();
  const {
    error: upLoadImageError,
    loading: uploadImageLoading,
    fetchData: uploadFetch,
    data: uploadData,
  } = useFetch<IDataResponse>();

  function handleInputChange(e: any) {
    setSubtopics(e.target.value);
  }

  const extractTextFromHtml = (htmlContent: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    return doc.body.textContent || "";
  };

  function handlePreview() {
    const body = {
      title: title,
      content: extractTextFromHtml(value),
      author: "Vegeel",
      category: selectedCategory,
      subTopics: subBlogTopics,
      image: imageUrl,
      visibility: "string",
      published: true,
    };
    saveTokenToLocalStore("blogPrev1ew", body);
    saveTokenToLocalStore("newBlog", "yes");
    navigate("/blog/preview-blog");
  }

  function handleSaveToDraft(e: any) {
    setDisabledBtn(true);
    if (!imageUrl) return null;

    const base64Image: string = imageUrl;

    const byteString = atob(base64Image.split(",")[1]);
    const mimeString = base64Image.split(",")[0].split(":")[1].split(";")[0];

    const byteArray = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([byteArray], { type: mimeString });
    const file = new File([blob], "image_file.png", { type: mimeString });

    uploadFetch(() => uploadFilesService(file));
  }
  useEffect(() => {
    if (uploadData !== null) {
      setSendimage(uploadData?.data.secure_url);

      const body = {
        title: title,
        content: extractTextFromHtml(value),
        author: "Vegeel",
        category: selectedCategory,
        subTopics: subBlogTopics,
        image: sendimage,
        visibility: "string",
        published: false,
      };

      if (sendimage) {
        fetchData(() => createBlog(body));
      }
    }
  }, [uploadData, sendimage]);

  useEffect(() => {
    if (upLoadImageError) {
      setDisabledBtn(false);
      toast.error(upLoadImageError);
    } else if (error) {
      setDisabledBtn(false);
      toast.error(error);
    }
  }, [error, upLoadImageError]);

  useEffect(() => {
    if (data !== null) {
      if (data.isSuccess) {
        setDisabledBtn(false);
        toast.success("Upload successful");
        localStorage.removeItem("blogPrev1ew");
        navigate("/blog/draft");
        setReload(true);
      }
    }
  }, [data]);

  useEffect(() => {
    const savedBlog: any = getTokenFromLocalStore("blogPrev1ew");
    if (savedBlog) {
      setTitle(savedBlog.title);
      setValue(savedBlog.content);
      setSelectedCategory(savedBlog.category);
      setSubBlogTopics(savedBlog.subTopics);
      setImageUrl(savedBlog.image);
    }
  }, []);

  function addTopic() {
    if (subtopics) {
      setSubBlogTopics((prevTopics) => [...prevTopics, subtopics]);
      setSubtopics("");
    }
  }

  function removeSubtopic(index: number) {
    setSubBlogTopics((prevTopics) => prevTopics.filter((_, i) => i !== index));
  }

  function handleTitle(e: any) {
    setTitle(e.target.value);
  }
  function prviousPageNav() {
    localStorage.removeItem("blogPrev1ew");
    navigate("/blog");
  }

  useEffect(() => {
    const isButtonDisabled =
      title.trim() === "" ||
      !imageUrl ||
      value.trim() === "" ||
      selectedCategory.length === 0;

    setDisabledBtn(isButtonDisabled);
  }, [title, imageUrl, value, selectedCategory]);

  useEffect(() => {
    setHeaderTitle("Blog");
  }, [setHeaderTitle]);

  return (
    <div className="h-full overflow-y-scroll flex flex-col py-3 gap-4">
      <div className="flex justify-between w-full items-center">
        <div className="flex flex-col">
          <h1 className="text-[18px] font-[500] leading-[30px] text-[#111111]">
            Upload new blog
          </h1>
          <p className="text-[#455468] text-[14px] font-[400]">
            Complete the process to upload a new blog.
          </p>
        </div>

        <div className="">
          <button
            onClick={prviousPageNav}
            className="py-[8px] px-[15px] bg-[white] rounded-[6px] flex justify-center items-center gap-[10px] border"
          >
            <img src={arrowLeft} alt="" />
            <h1 className="font-[500] text-[14px] leading-[24px] text-[#455468]">
              Back to blog post
            </h1>
          </button>
        </div>
      </div>

      <div className="flex w-full h-full bg-white p-3 gap-6 overflow-hidden">
        <div className="h-[85%] w-[60%] flex flex-col gap-3">
          <BlogInput
            label="Title"
            placeholder="Enter a blog title"
            required
            type="text"
            value={title}
            onChange={handleTitle}
          />

          <BlogEditor setValue={setValue} value={value} />
        </div>

        <div className="w-[40%] p-3 border-l-2 flex flex-col h-[100%] gap-4 overflow-y-scroll">
          <div>
            <BlogImageUploader
              imageData={imageData}
              setImageData={setImageData}
              imageUrl={imageUrl}
              setImageUrl={setImageUrl}
            />
          </div>
          <div>
            <Selector
              setSelectedOptions={setSelectedCategory}
              selectedOptions={selectedCategory}
            />
          </div>

          <div>
            <BlogInput
              label="Subtopics (optional)"
              placeholder="Enter subtopic"
              required={false}
              type="text"
              value={subtopics}
              onChange={handleInputChange}
            />
          </div>
          <div
            onClick={addTopic}
            className="text-[#1B4DFF] text-[14px] font-[500] flex items-center gap-2 justify-end cursor-pointer"
          >
            <CustomIcon SvgIcon={plusIncon} size={26} />
            Add topic
          </div>

          <div>
            {subBlogTopics.map((value, index) => (
              <SubTopic
                key={index}
                index={index}
                value={value}
                cancel={() => removeSubtopic(index)}
              />
            ))}
          </div>

          <div className="flex gap-4 items-center flex-col-reverse justify-between py-3 px-3">
            <button
              disabled={disabledBtn}
              onClick={handleSaveToDraft}
              className={`flex flex-row justify-center items-center py-[8px] px-[15px] gap-2.5 w-full ${
                disabledBtn
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-white border border-slate-300 shadow-md"
              } rounded-md`}
            >
              Save as Draft
            </button>

            <button
              disabled={disabledBtn}
              onClick={handlePreview}
              className={`py-[8px] px-[15px] ${
                disabledBtn
                  ? "bg-[#1B4DFF] opacity-50 cursor-not-allowed"
                  : "bg-[#1B4DFF]"
              } border border-[#1B4DFF] rounded-[6px] flex justify-center items-center gap-[10px] text-white w-full`}
            >
              <h1 className="font-[500] text-[14px] leading-[24px]">
                Preview blog
              </h1>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
