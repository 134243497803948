import React from "react";
import vegeelLogo from "assets/images/vegeel-logo.svg";
import overlay from "assets/images/anthenticated_overlay.svg";
import { Outlet } from "react-router-dom";

export default function Unauthenticated() {
  return (
    <div className="h-full w-full flex p-4">
      <div className="h-full w-1/2 relative bg-[#06249C] flex justify-center items-center rounded-[20px]">
        <img
          src={overlay}
          alt=""
          className="absolute top-0 left-0 h-full w-full object-cover rounded-[20px]"
        />
        <div
          className="h-[50%] w-[70%] flex
        flex-col justify-between z-20"
        >
          <img src={vegeelLogo} alt="" className="w-[114px]" />
          <div className="text-white flex flex-col gap-4">
            <h1 className="text-[60px] font-medium leading-[66px] tracking-[-2%] ">
              Effortless security and compliance management
            </h1>
            <p className="text-base tracking-[-0.3px] leading-[28px]">
              Our platform is tailored to cater to the unique compliance needs
              of African companies, ensuring seamless adherence to local
              regulations.
            </p>
          </div>
        </div>
      </div>
      <div className="h-full w-1/2 flex justify-center items-center">
        <Outlet />
      </div>
    </div>
  );
}
