import React from "react";
import CustomIcon from "components/custom-icon";
import { ReactComponent as file } from "assets/icons/file-05.svg";

interface NotifyItemsProps {
  avatar: string;
  title: string;
  audit: string;
  date: string;
  pdf: {
    name: string;
    size: string;
    type: string;
    date: string;
  };
}

const NotifyItems: React.FC<NotifyItemsProps> = ({
  avatar,
  title,
  audit,
  date,
  pdf,
}) => {
  return (
    <div className="w-[300px]">
      {/* <div className='flex gap-3'>
				<div>
					<div className='w-[32px] aspect-square rounded-full overflow-hidden items-center justify-center'>
						<img className='w-full h-full object-cover' src={avatar} alt='' />
					</div>
				</div>
				<div className='w-full'>
					<div>
						<div className='w-full flex items-center justify-between gap-16'>
							<p className='text-sm font-medium text-[#455468]'>{title}</p>
							<div className='w-2 bg-[#027A48] aspect-square rounded-full'></div>
						</div>
						<div className='w-full flex items-center justify-between mt-1'>
							<p className='text-xs text-[#667085]'>
								{date} <span className='underline text-[#111111]'>{audit}</span>
							</p>
							<p className='text-xs text-[#667085]'> 10 mins</p>
						</div>
						<div className='flex gap-2 bg-[#FAFAFA] p-2 rounded-md w-[244px] mt-4'>
							<div>
								<div className='w-[32px] aspect-square flex rounded-md items-center justify-center bg-[#E5EAFC]'>
									<CustomIcon SvgIcon={file} size={16} />
								</div>
							</div>
							<div className='w-full'>
								<div className='flex items-center gap-1'>
									<p className='text-xs text-[#455468] font-bold'>{pdf.name}</p>
									<div className='w-0.5 aspect-square rounded-full bg-[#667085]'></div>
									<p className='text-xs text-[#667085]'>{pdf.size}</p>
								</div>
								<div className='flex items-center gap-1'>
									<p className='text-xs text-[#667085]'>{pdf.type}</p>
									<div className='w-0.5 aspect-square rounded-full bg-[#667085]'></div>
									<p className='text-xs text-[#667085]'>{pdf.date}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}
      <h1>No Notification</h1>
    </div>
  );
};

export default NotifyItems;
