import React, { useEffect } from "react";
import img from "assets/icons/Alert-triangle.svg";
import Button from "components/teams/buttons";
import useFetch from "hooks/useFetch";
import { deleteBlog } from "services/blog";
import { toast } from "sonner";
import { Navigate, useNavigate } from "react-router-dom";
import { useBlog } from "context/blog";
interface deleteProps {
  open: boolean;
  setOpen: Function;
  id: number;
}
interface IDataResponse {
  isSuccess: boolean;
  isError: boolean;
  description: string;
  status: string;
}

function DeleteBLogModal({ open, setOpen, id }: deleteProps) {
  const { error, loading, fetchData, data } = useFetch<IDataResponse>();
  const { setReload } = useBlog();
  const navigate = useNavigate();
  function handleDelete(id: any) {
    fetchData(() => deleteBlog(id));
  }

  useEffect(() => {
    if (data !== null) {
      if (data.isSuccess) {
        // console.log(data);
        toast.success("Delete successful");
        localStorage.removeItem("blogPrev1ew");
        navigate("/blog");
        setReload(true);
      }
    }
  }, [data]);
  return (
    <div
      className={`bg-black/20 fixed top-0 left-0 h-full w-full overflow-hidden ${
        open ? "block" : "hidden"
      }`}
    >
      <div className="flex justify-center items-center h-[100vh]">
        <div className="p-[24px] bg-white  rounded-[6px] w-[400px] relative">
          <div className="w-[48px] h-[48px] rounded-[28px] bg-[#fff5f5] flex justify-center items-center">
            <img src={img} alt="danger" title="danger" />
          </div>
          {/* <div
            className="absolute top-[16px] right-[16px] cursor-pointer"
            onClick={() => setOpen(false)}
          >
            <CustomIcon SvgIcon={Times} size={12} />
          </div> */}
          <div className="pt-[20px]">
            <h1 className="font-[700] text-[18px] leading-[30px] text-[#101828]">
              Delete Blog?
            </h1>
            <p className="pt-[8px] text-[14px] leading-[24px] font-[400] text-[#455468]">
              Are you sure you want to delete this Blog? All information about
              this blog will be lost.
            </p>
          </div>
          <div className="pt-[32px] flex gap-[16px]">
            <Button
              variant="outline"
              text="Cancel"
              onClick={() => setOpen(false)}
            />
            <Button
              onClick={() => handleDelete(id)}
              variant="danger"
              text="Delete"
            />
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

export default DeleteBLogModal;
