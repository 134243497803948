import React, { useEffect, useState } from "react";
import { ReactComponent as arrow } from "assets/icons/arrow-left.svg";
import { useNavigate, useParams } from "react-router-dom";
import { testData } from "constants/business";
import CustomIcon from "components/custom-icon";
import BusinessInfo from "components/business/business-info";
import AccessFeatures from "components/business/access-features";
import { ReactComponent as flutter } from "assets/icons/flutter.svg";
import mike from "assets/images/mike.jpg";
import olamide from "assets/images/olamide.jpg";
import ApprovalBusinessInfo from "components/approval/business-info";
import { approveBusiness, getBusinessById } from "services/teams";
import useFetch from "hooks/useFetch";
import Loader from "components/loader";
import { toast } from "sonner";

interface Framework {
  availability: true;
  createdBy: "SuperAdmin Vegeel";
  createdDate: "2024-09-17";
  description: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  name: string;
  publicId: string;
  type: string;
  year: number;
}

interface TenantAdmin {
  createdBy: string;
  createdDate: string;
  email: string;
  firstName: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  lastName: string;
  publicId: string;
}
interface BusinessData {
  appAccess: string;
  appModules: [];
  approved: boolean;
  businessStatus: string;
  companyDomain: string;
  companyEmail: string;
  companyName: string;
  createdBy: string;
  createdDate: string;
  customerSuccessManager: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  publicId: string;
  salesRep: string;
  subscriptionType: string;
  tenantAdmins: TenantAdmin[];
  frameworks: Framework[];
}

interface FetchResponse {
  data: BusinessData;
  isSuccess: boolean;
  isError: boolean;
  description: string;
  status: string;
}

export default function SingleApproval() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data, error, loading, fetchData } = useFetch<FetchResponse>();
  const {
    data: data1,
    error: error1,
    loading: loading1,
    fetchData: fetchData1,
  } = useFetch<FetchResponse>();

  useEffect(() => {
    if (id) {
      fetchData(() => getBusinessById(id));
    }
  }, [id]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleSubmit = async () => {
    fetchData1(() => approveBusiness(id));
  };

  useEffect(() => {
    if (data1) {
      toast.success("Approved");
      navigate("/approvals");
    }
  }, [data1]);

  useEffect(() => {
    if (error1) {
      toast.error(error1);
    }
  }, [error1]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="w-full h-full overflow-y-scroll py-[24px] flex flex-col gap-6 bg-[#F8F9FA]">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          {/* <CustomIcon SvgIcon={business.cImg} size={36} /> */}
          <h1 className="text-[18px] font-[500] leading-[30px]">
            {data?.data.companyName}
          </h1>
        </div>

        <div className="flex gap-4">
          <button
            onClick={handleBack}
            className="py-[8px] px-[15px] bg-white border border-[#D7DFE9] rounded-[6px] flex justify-center items-center gap-[10px] text-[#455468]"
          >
            <CustomIcon SvgIcon={arrow} size={20} />
            <h1 className="font-[500] text-[14px] leading-[24px]">Back</h1>
          </button>
          <button
            className="py-[8px] px-[15px] bg-[#1B4DFF] border border-[#1B4DFF] rounded-[6px] flex justify-center items-center gap-[10px] text-white"
            onClick={handleSubmit}
          >
            <h1 className="font-[500] text-[14px] leading-[24px]">
              {loading1 ? "Approving..." : "Approve & send link"}
            </h1>
          </button>
        </div>
      </div>

      <div className="">
        <ApprovalBusinessInfo business={data?.data} />
      </div>
    </div>
  );
}
