// @ts-nocheck
import { useState, useCallback } from 'react';
import axios, { AxiosError } from 'axios';
import dayjs from 'dayjs';

interface FetchState<T> {
	data: T | null;
	error: string | null;
	loading: boolean;
}

interface RetryConfig {
	retries?: number;
	retryDelay?: number;
}

const useFetch = <T>() => {
	const [fetchState, setFetchState] = useState<FetchState<T>>({
		data: null,
		error: null,
		loading: false,
	});

	const fetchData = useCallback(
		async (
			apiCall: () => Promise<T>,
			retryConfig: RetryConfig = { retries: 3, retryDelay: 1000 }
		) => {
			const { retries, retryDelay } = retryConfig;
			let attempt = 0;

			const attemptFetch = async (): Promise<void> => {
				setFetchState((prevState) => ({ ...prevState, loading: true }));
				try {
					const data = await apiCall();
					setFetchState({ data, error: null, loading: false });
				} catch (err: unknown) {
					const error = err as AxiosError;
					let errorMessage = 'An error occurred';

					if (axios.isCancel(error)) {
						errorMessage = 'Request canceled';
					} else if (error.code === 'ECONNABORTED') {
						errorMessage = 'Request timed out';
					} else if (!error.response) {
						errorMessage = 'Network error';
					} else {
						const responseData = error.response.data as {
							type?: string;
							title?: string;
							status?: number;
							detail?: string;
							instance?: string;
							description?: string;
							timestamp?: string;
							isError?: boolean;
							isSuccess?: boolean;
							error?: string;
							error_description?: string;
						};

						if (responseData) {
							if (responseData.error && responseData.error_description) {
								errorMessage = responseData.error_description;
							} else if (responseData.detail || responseData.description) {
								if (
									responseData.status === 403 &&
									responseData.detail?.includes('JWT expired')
								) {
									const expiryTimeMatch = responseData.detail.match(
										/JWT expired at ([\d-:T.]+)Z/
									);
									const expiryTime = expiryTimeMatch
										? expiryTimeMatch[1]
										: null;
									const formattedExpiryTime = expiryTime
										? dayjs(expiryTime).format('DD:MM:YYYY')
										: null;
									errorMessage = `This set password token has expired on ${formattedExpiryTime}. Please contact our help desk for assistance.`;
								} else {
									errorMessage =
										responseData.detail ||
										responseData.description ||
										errorMessage;
								}
							} else {
								switch (error.response.status) {
									case 400:
										if (
											responseData.detail?.includes('NoHttpResponseException')
										) {
											if (attempt < retries) {
												attempt++;
												setTimeout(attemptFetch, retryDelay);
												return;
											}
										}
										errorMessage =
											'Bad Request: The server could not understand the request due to invalid syntax.';
										break;
									case 401:
										errorMessage =
											'Unauthorized: Access is denied due to invalid credentials.';
										break;
									case 403:
										errorMessage =
											"Forbidden: You don't have permission to access this resource.";
										break;
									case 404:
										errorMessage =
											'Not Found: The requested resource could not be found.';
										break;
									case 500:
										errorMessage =
											"Internal Server Error: The server has encountered a situation it doesn't know how to handle.";
										break;
									case 503:
										errorMessage =
											'Service Unavailable: The server is not ready to handle the request.';
										break;
									default:
										errorMessage = error.response.data?.message || errorMessage;
								}
							}
						}
					}

					setFetchState({ data: null, error: errorMessage, loading: false });
				}
			};

			attemptFetch();
		},
		[]
	);

	return { ...fetchState, fetchData };
};

export default useFetch;
