import { Checkbox, Modal } from "antd";
import { useEffect, useState } from "react";
import { ReactComponent as lock } from "assets/icons/lock-open-01.svg";
import { ReactComponent as off } from "assets/icons/off.svg";
import CustomIcon from "components/custom-icon";
import useFetch from "hooks/useFetch";
import { updatePasswordService } from "services/auth";
import { toast } from "sonner";
import Loader from "..";

interface Props {
  open: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  loading: boolean;
}

const PasswordModal: React.FC<Props> = ({
  open,
  handleOk,
  handleCancel,
  loading,
}) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [seePassword, setSeePassword] = useState(false);
  const onNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPasswordValue = e.target.value;
    setNewPassword(newPasswordValue);

    // Check password conditions
    setHasUppercase(/[A-Z]/.test(newPasswordValue));
    setHasLowercase(/[a-z]/.test(newPasswordValue));
    setHasNumber(/[0-9]/.test(newPasswordValue));
    setHasSpecialChar(/[\W_]/.test(newPasswordValue)); // Non-word character or underscore
  };

  const {
    data: updatePasswordData,
    error: updatePasswordError,
    loading: updatePasswordLoading,
    fetchData: fetchDataUpdatePassword,
  } = useFetch<any>();

  //   {
  // 	"oldPassword": "string",
  // 	"newPassword": "string",
  // 	"confirmPassword": "string"
  //   }

  const data = {
    oldPassword: currentPassword,
    newPassword,
    confirmPassword,
  };

  const handleUpdate = () => {
    fetchDataUpdatePassword(() => updatePasswordService(data));
  };

  const handleSeePassword = () => {
    setSeePassword(!seePassword);
  };

  useEffect(() => {
    if (updatePasswordData) {
      console.log("updatePasswordData", updatePasswordData);
    }
  }, [updatePasswordData]);

  useEffect(() => {
    if (updatePasswordError) {
      toast.error(updatePasswordError);
    }
  }, [updatePasswordError]);

  // if (updatePasswordLoading) {
  //   return <Loader />;
  // }

  return (
    <Modal
      open={open}
      title="Change Password"
      centered
      onOk={handleOk}
      onCancel={handleCancel}
      width={450}
      footer={
        <div className=" w-full flex items-center justify-end gap-6 mt-[2rem] mb-4">
          <button
            className="py-2 px-4 rounded-md bg-white border font-medium text-sm border-[#D7DFE9]"
            key="back"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            className="py-2 px-4 rounded-md text-white font-medium text-sm bg-[#1B4DFF] border border-[#1B4DFF]"
            key="submit"
            onClick={handleUpdate}
          >
            {updatePasswordLoading ? "Updating Password" : "Update password"}
          </button>
        </div>
      }
    >
      <div className="mt-5 ">
        <form className="flex flex-col gap-4">
          {/* Current password */}
          <div>
            <label
              htmlFor="current-password"
              className="font-medium text-[#455468] text-sm"
            >
              Current password<sup className="text-[#1B4DFF]">*</sup>
            </label>
            <div className="flex items-center gap-2 border border-[#AFBACA] p-4 rounded-md mt-1">
              <CustomIcon SvgIcon={lock} size={20} />
              <input
                id="current-password"
                className="w-full placeholder:text-[#8897AE] placeholder:text-sm border-l px-2 bg-transparent outline-none"
                type={seePassword ? "text" : "password"}
                placeholder="Enter current password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <div onClick={handleSeePassword}>
                <CustomIcon SvgIcon={off} size={20} />
              </div>
            </div>
          </div>

          {/* New password */}
          <div>
            <label
              htmlFor="new-password"
              className="font-medium text-[#455468] text-sm"
            >
              New password<sup className="text-[#1B4DFF]">*</sup>
            </label>
            <div className="flex items-center gap-2 border border-[#AFBACA] p-4 rounded-md mt-1">
              <CustomIcon SvgIcon={lock} size={20} />
              <input
                id="new-password"
                className="w-full placeholder:text-[#8897AE] placeholder:text-sm border-l px-2 bg-transparent outline-none"
                type={seePassword ? "text" : "password"}
                placeholder="Enter new password"
                value={newPassword}
                onChange={onNewPasswordChange}
              />
              <div onClick={handleSeePassword}>
                <CustomIcon SvgIcon={off} size={20} />
              </div>
            </div>
          </div>

          {/* Password requirements */}
          <div>
            <p className="text-sm text-[#455468]">
              Make sure your new password is 8 or more characters and has the
              following:
            </p>
            <div className="flex flex-col gap-4 mt-4">
              <div className="flex items-center gap-4">
                <Checkbox checked={hasUppercase} />
                <p className="text-[#455468] text-sm">An uppercase letter</p>
              </div>
              <div className="flex items-center gap-4">
                <Checkbox checked={hasLowercase} />
                <p className="text-[#455468] text-sm">A lowercase letter</p>
              </div>
              <div className="flex items-center gap-4">
                <Checkbox checked={hasNumber} />
                <p className="text-[#455468] text-sm">A number</p>
              </div>
              <div className="flex items-center gap-4">
                <Checkbox checked={hasSpecialChar} />
                <p className="text-[#455468] text-sm">A special character</p>
              </div>
            </div>
          </div>

          {/* Confirm password */}
          <div>
            <label
              htmlFor="confirm-password"
              className="font-medium text-[#455468] text-sm"
            >
              Confirm password<sup className="text-[#1B4DFF]">*</sup>
            </label>
            <div className="flex items-center gap-2 border border-[#AFBACA] p-4 rounded-md mt-1">
              <CustomIcon SvgIcon={lock} size={20} />
              <input
                id="confirm-password"
                className="w-full placeholder:text-[#8897AE] placeholder:text-sm border-l px-2 bg-transparent outline-none"
                type={seePassword ? "text" : "password"}
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <div onClick={handleSeePassword}>
                <CustomIcon SvgIcon={off} size={20} />
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default PasswordModal;
