import useFetch from "hooks/useFetch";
import React, { createContext, useContext, useEffect, useState } from "react";
import { getAllFrameworkService } from "services/framework";
import { getTokenFromLocalStore, saveKeyToLocalStore } from "utils";

export interface IUserDataResponse {
  description: string;
  isError: boolean;
  isSuccess: boolean;
  status: string;
  timestamp: string;
  data: IUserDataDetails;
}

export interface IUserDataDetails {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  emailVerified: boolean;
  attributes: {
    [key: string]: string[] | null;
    trainings: string[];
    appRole: string[];
    vulnerableByPhishing: string[];
    position: string[] | null;
    profilePicture: string[] | null;
    department: string[];
    dateAdded: string[];
  };
  userProfileMetadata: null;
  userRole: string | null;
  self: null;
  origin: null;
  createdTimestamp: number;
  enabled: boolean;
  totp: boolean;
  federationLink: string | null;
  serviceAccountClientId: string | null;
  credentials: string | null;
  disableableCredentialTypes: any[];
  requiredActions: any[];
  federatedIdentities: string | null;
  realmRoles: string | null;
  clientRoles: string | null;
  clientConsents: string | null;
  notBefore: 0;
  applicationRoles: string | null;
  socialLinks: string | null;
  groups: string | null;
  access: string | null;
  publicId: string | null;
  is2faEnabled: boolean | null;
  sessionTimeout: any | null;
  automaticLogoutTime: any | null;
  roleName: any | null;
}

interface AppContextType {
  userInfoState: any | null;
  setUserInfo: (userInfo: any) => void;
  getUserInfo: () => any | null;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userInfoState, setUserInfoState] = useState<any>(null);

  const setUserInfo = (userInfo: any) => {
    setUserInfoState(userInfo);
    saveKeyToLocalStore("userInfo", JSON.stringify(userInfo));
  };

  const getUserInfo = (): any => {
    const userInfo = getTokenFromLocalStore<string>("userInfo");
    if (userInfo) {
      const data: any = JSON.parse(userInfo);
      return data;
    }
    return null;
  };

  return (
    <AppContext.Provider
      value={{
        userInfoState,
        setUserInfo,
        getUserInfo,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
